import Keycloak from 'keycloak-js';
import {SETTINGS} from "@smartpush-front/styles";
import {
  getAccessWithRefreshTokenSuccess,
  getLocationOfferBeforeLogin,
  LS_KEYCLOAK_ACCESS_TOKEN_KEY,
  LS_KEYCLOAK_REFRESH_TOKEN_KEY,
  LS_KEYCLOAK_TOKENS,
  LS_OAUTH_TOKENS,
  LS_CREDENTIAL_TOKEN,
  store,
  submitLoginSuccessKeycloak,
  userLogged,
} from '@smartpush-front/store';
import {getPartnerGlobalSettings} from "../../../styles/src/lib/partners";
import {userLogout} from "../../../../apps/phoenix/src/app/pages/LoginPage/actions";
export const keycloak = Keycloak(SETTINGS.iam);

export const initializeKeycloak = async (accessToken: string|null, refreshToken: string|null) => {
  const environment = process.env.REACT_APP_APP_ENV || process.env.NX_APP_ENVIRONMENT;
  try {
    const result = await keycloak.init(
      {
        token: accessToken ?? '',
        refreshToken: refreshToken ?? '',
        flow: 'standard',
        timeSkew: 0,
        checkLoginIframe: false,
        enableLogging: environment !== 'prod',
        pkceMethod: 'S256',
      }
    );

    return {
      authenticated: result,
      isAuthenticated: true,
    }
  } catch (e) {
    return e;
  }
}

keycloak.onAuthRefreshSuccess = function () {
  store.dispatch(userLogged(keycloak.token, keycloak.refreshToken))
  store.dispatch(getAccessWithRefreshTokenSuccess(keycloak.token, keycloak.refreshToken));
}
keycloak.onAuthRefreshError = function () {
  store.dispatch(userLogout());
}
keycloak.onAuthSuccess = async () => {
  if (SETTINGS.partnerName !== 'Smerra') {
    await store.dispatch(getLocationOfferBeforeLogin(`${window.location.pathname}${window.location.search}`));
  }
  await store.dispatch(submitLoginSuccessKeycloak(keycloak.token, keycloak.refreshToken));
  await store.dispatch(getAccessWithRefreshTokenSuccess(keycloak.token, keycloak.refreshToken));
}

export const isKeycloakAuthentication = () => {
  const partnerConfig = getPartnerGlobalSettings();

  return !partnerConfig?.iam.sso;
}

export function getIamRealmName(): string {
  const partnerConfig = getPartnerGlobalSettings();

  return partnerConfig?.iam.realm;
}

export const isWithPostMessageAuthentication = () => {
  const partnerConfig = getPartnerGlobalSettings();

  return partnerConfig?.iam?.tokenFromPostMessage ?? false;
}

export const logoutKeycloak = async () => {
  keycloak.redirectUri = window.location.origin;
  const offersRoute = SETTINGS.partnerName === "Smerra" ? "/accueil" : "/offers";
  localStorage.removeItem(LS_KEYCLOAK_TOKENS);
  localStorage.removeItem(LS_KEYCLOAK_ACCESS_TOKEN_KEY);
  localStorage.removeItem(LS_KEYCLOAK_REFRESH_TOKEN_KEY);
  localStorage.removeItem(LS_OAUTH_TOKENS);
  localStorage.removeItem(LS_CREDENTIAL_TOKEN);
  await Promise.all([
    store.dispatch(getLocationOfferBeforeLogin(offersRoute)),
    keycloak.logout()
  ]);
}

export const loginToKeycloak = (redirectUri: string|null, scopes: string) => {
  if (redirectUri) {
    keycloak.redirectUri = redirectUri;
  }
  return keycloak.login({scope: scopes});
}

export const initKeycloakWithoutOptions = async (accessToken: string|undefined = undefined, refreshToken: string|undefined = undefined) => {
  const result = await initializeKeycloak(accessToken, refreshToken);

  return {
    authenticated: result,
  }
}
