import phoenixStyle           from './partners/phoenix';
import adlpStyle              from './partners/adlp';
import smerraStyle            from './partners/smerra';
import studentBeansStyle      from './partners/studentBeans';
import edfStyle               from './partners/edf';
import priziStyle              from './partners/prizi';
import bpvfStyle               from './partners/bpvf';
import reducavenueStyle        from './partners/reducavenue';
import ace                     from './partners/ace';
import heyme from "./partners/heyme";
import bconnect from './partners/bconnect';


const styles = {
  'localhost': phoenixStyle,
  'phoenix': phoenixStyle,
  'faplus': adlpStyle,
  'smerra': smerraStyle,
  'studlife': smerraStyle,
  'studentbeans': studentBeansStyle,
  'edf': edfStyle,
  'prizi':priziStyle,
  'reducavenue':reducavenueStyle,
  'bonsplansbybpvf': bpvfStyle,
  'ace': ace,
  'heyme': heyme,
  'bconnect': bconnect
}

export function getPartnerFromHostname() {
  const domain = window.location.hostname;
  if (domain.includes('.phoenix.fr') || domain.includes('.smartpush.fr') || domain.includes('.smerra.fr')) {
    let partnerName = domain.split('.')[0];

    if (partnerName.includes('-')) {
      partnerName = domain.split('-')[0];
    }

    return partnerName;
  }

  if (domain.includes('aceetvous.fr')) {
    return 'ace';
  }

  if (domain.includes('faplus.fr')) {
    return 'faplus';
  }

  throw new Error('Unknown partner');
}

export function getPartnerGlobalSettings() {
  const partner = getPartnerFromHostname();

  if (!styles[partner]) {
    return {}
  }
  return styles[partner];
}
