/*
 * Token Constants
 */

/**
 * Offer CONSTANTS
 *
 * @type {string}
 */
export const HANDLE_TOKEN_FRONT = 'SmartPush/Tokens/HANDLE_TOKEN_FRONT'

export const CALL_CREDENTIAL_TOKEN_FRONT = 'SmartPush/Tokens/CALL_CREDENTIAL_TOKEN_FRONT';
export const CALL_CREDENTIAL_TOKEN_FRONT_SUCCESS = 'SmartPush/Tokens/CALL_CREDENTIAL_TOKEN_FRONT_SUCCESS';
export const CALL_CREDENTIAL_TOKEN_FRONT_ERROR = 'SmartPush/Tokens/CALL_CREDENTIAL_TOKEN_FRONT_ERROR';

export const CALL_TOKEN_EXCHANGE_FRONT = 'SmartPush/Tokens/CALL_TOKEN_EXCHANGE_FRONT';
export const CALL_TOKEN_EXCHANGE_FRONT_SUCCESS = 'SmartPush/Tokens/CALL_TOKEN_EXCHANGE_FRONT_SUCCESS';
export const CALL_TOKEN_EXCHANGE_FRONT_ERROR = 'SmartPush/Tokens/CALL_TOKEN_EXCHANGE_FRONT_ERROR';

export const SET_TOKEN_IFRAME_FRONT = 'SmartPush/Tokens/SET_TOKEN_IFRAME_FRONT';
