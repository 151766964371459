import {SETTINGS} from "@smartpush-front/styles";
import {isKeycloakAuthentication} from "../utils/keycloak";
import {getPartnerFromHostname} from "../../../styles/src/lib/partners";

export let SMARTPUSH_API_EMAIL: string | undefined;
export let SMARTPUSH_API_PASSWORD: string | undefined;
if (process.env.NODE_ENV === 'development') {
  SMARTPUSH_API_EMAIL    = process.env.REACT_APP_SMARTPUSH_API_EMAIL || process.env.NX_SMARTPUSH_API_EMAIL;
  SMARTPUSH_API_PASSWORD = process.env.REACT_APP_SMARTPUSH_API_PASSWORD || process.env.NX_SMARTPUSH_API_PASSWORD;
}

let url = process.env.REACT_APP_SMARTPUSH_API_BASE_URL || process.env.NX_SMARTPUSH_API_BASE_URL;
if (SETTINGS.apiUrl) {
  url = SETTINGS.apiUrl
}
if (isKeycloakAuthentication()) {
  url = url.replace('https://','')
  const prefix = SETTINGS.api?.prefix ?? getPartnerFromHostname();
  url = `https://${prefix}-${url}`;
}

export const SMARTPUSH_API_BASE_URL = url;

export const POZEO_PROVIDER = '12a19d5b-4103-4378-ba0c-8bb9393cdf79';
// USER
export const SMARTPUSH_API_URL_TOKEN                = SMARTPUSH_API_BASE_URL + '/token';
export const SMARTPUSH_API_URL_USERS                = SMARTPUSH_API_BASE_URL + '/users';
export const SMARTPUSH_API_URL_USER_ME              = SMARTPUSH_API_BASE_URL + '/user/me';
export const SMARTPUSH_API_URL_USER                 = SMARTPUSH_API_BASE_URL + '/users/:userId';
export const SMARTPUSH_API_URL_USER_ME_BANK_INFO    = SMARTPUSH_API_BASE_URL + '/v2/user/me/bank_info';
export const SMARTPUSH_API_URL_USER_ME_CASHBACK     = SMARTPUSH_API_BASE_URL + '/users/:userId/cashback';
export const SMARTPUSH_API_URL_USER_CASHBACK_PAYOUT = SMARTPUSH_API_BASE_URL + '/v2/user/me/cashbacks/payout';
export const SMARTPUSH_API_URL_USER_ORDERS          = SMARTPUSH_API_BASE_URL + '/users/:userId/orders';
export const SMARTPUSH_API_URL_USER_ORDER           = SMARTPUSH_API_BASE_URL + '/orders/:orderId';
export const SMARTPUSH_API_URL_USER_CARD_TOKENS     = SMARTPUSH_API_BASE_URL + '/v2/cardtokens';
export const SMARTPUSH_API_URL_USER_CARD_TOKEN      = SMARTPUSH_API_BASE_URL + '/v2/cardtokens/:cardTokenId';
export const SMARTPUSH_API_URL_CARD_TYPES           = SMARTPUSH_API_BASE_URL + '/v2/cardtypes';
export const SMARTPUSH_API_URL_USER_REGISTER        = SMARTPUSH_API_BASE_URL + '/v1/user/registerWithConsent';
export const SMARTPUSH_API_URL_USER_WALLET          = SMARTPUSH_API_BASE_URL + '/v1/user/wallet';
export const SMARTPUSH_API_URL_USER_RESET_PASSWORD  = SMARTPUSH_API_BASE_URL + '/v1/user/reset/password';
export const SMARTPUSH_API_URL_USER_UPDATE_PASSWORD = SMARTPUSH_API_BASE_URL + '/v1/user/update/password';
export const SMARTPUSH_API_URL_PARTNER_SETTINGS     = SMARTPUSH_API_BASE_URL + '/v1/settings/';
export const SMARTPUSH_API_URL_PARTNER_SETTINGS_V2     = SMARTPUSH_API_BASE_URL + '/v2/settings';
export const SMARTPUSH_API_URL_USER_SSO             = SMARTPUSH_API_BASE_URL + '/v1/user/sso/smerra';
export const SMARTPUSH_API_URL_USER_CONFIRM_REGISTRATION = SMARTPUSH_API_BASE_URL + '/confirm';
export const SMARTPUSH_API_URL_AUTH_TOKENS = SMARTPUSH_API_BASE_URL + '/v2/auth-tokens';

// FILTERS
export const SMARTPUSH_API_URL_BRANDS        = SMARTPUSH_API_BASE_URL + '/brands';
export const SMARTPUSH_API_URL_BRAND         = SMARTPUSH_API_BASE_URL + '/brands/:brandId';
export const SMARTPUSH_API_URL_CITIES   = SMARTPUSH_API_BASE_URL + '/cities';
export const SMARTPUSH_API_URL_CITY_BY_ID    = SMARTPUSH_API_BASE_URL + '/cities/:id';
export const SMARTPUSH_API_URL_CATEGORIES    = SMARTPUSH_API_BASE_URL + '/categories';
export const SMARTPUSH_API_URL_TYPES        = SMARTPUSH_API_BASE_URL + '/offertypes';
export const SMARTPUSH_API_URL_TAGS         = SMARTPUSH_API_BASE_URL + '/tags';
export const SMARTPUSH_API_URL_BRAND_SUGGEST = SMARTPUSH_API_BASE_URL + '/brands/suggest';

// OFFERS
export const SMARTPUSH_API_URL_OFFER              = SMARTPUSH_API_BASE_URL + '/offers/:offerId';
export const SMARTPUSH_API_URL_OFFERS             = SMARTPUSH_API_BASE_URL + '/offers';
export const SMARTPUSH_API_URL_RECOMMENDED_OFFERS = SMARTPUSH_API_BASE_URL + '/users/:userId/brands/predict';
export const SMARTPUSH_API_URL_REDIRECTED_OFFERS  = SMARTPUSH_API_BASE_URL + '/v1/discount/redirect/to/external/offer/';

// OFFERS BY BRAND
export const SMARTPUSH_API_URL_BRAND_OFFERS       = SMARTPUSH_API_BASE_URL + '/brands/:brandId/offers';

// OFFER
export const SMARTPUSH_API_URL_OFFER_SHOPS = SMARTPUSH_API_BASE_URL + '/offers/:offerId/shops';

// OFFER
export const SMARTPUSH_API_URL_CREATE_ORDER = SMARTPUSH_API_BASE_URL + '/orders';

// DISCOUNT
export const SMARTPUSH_API_URL_OFFER_ADD_DISCOUNT = SMARTPUSH_API_BASE_URL + '/users/:userId/coupon';

//PSP
export const SMARTPUSH_API_URL_GET_CARD_FEES = `${SMARTPUSH_API_BASE_URL}/order-fees`;

//
//  GOOGLE MAP API KEY
//

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? process.env.NX_GOOGLE_MAP_API_KEY;

//
// ANALYTICS_OFFER_VIEW
//

export const  SMARTPUSH_API_URL_ANALYTICS_OFFER_VIEW = SMARTPUSH_API_BASE_URL + '/v1/analytics/offer-view';
export const  SMARTPUSH_API_URL_ANALYTICS_OFFER_WALLET = SMARTPUSH_API_BASE_URL + '/v1/analytics/offer-wallet';
export const  SMARTPUSH_API_URL_ANALYTICS_OFFER_PRINT = SMARTPUSH_API_BASE_URL + '/v1/analytics/offer-print';

//
// KEYCLOAK TOKEN
//

export const URL_CALL_TOKEN_CREDENTIALS = ':baseUrl/realms/:realmName/protocol/openid-connect/token';

// Status Code HTTP
export const HTTP_BAD_REQUEST = 400;
export const HTTP_FORBIDDEN = 403;
export const HTTP_PAYMENT_REQUIRED = 402;
export const HTTP_CONFLICT = 409;
export const HTTP_SERVICE_UNAVAILABLE = 503;
export const HTTP_UNPROCESSABLE_ENTITY = 422;

// 3DS result possibility
export const DS_ACCEPT = 'accept';
export const DS_CANCEL = 'cancel';
export const DS_PENDING = 'pending';
export const DS_DECLINE = 'decline';
export const DS_EXCEPTION = 'exception';
