import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider, useDispatch, useSelector} from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {theme} from '@smartpush-front/styles';
import {CircularProgress, MuiThemeProvider} from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
// Create redux store with history

import {store, history} from "@smartpush-front/store";
import {getPartnerFromHostname} from "../../../libs/styles/src/lib/partners";
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { Integration } from '@sentry/types';
import {SMARTPUSH_API_BASE_URL} from "@smartpush-front/api";
import {makeSelectPartnerSettings} from "./app/pages/LoginPage/selectors";
import {callSettings} from "./app/components/settings/actions";
import {useInjectSaga} from "./utils/injectSaga";
import settingsSaga from "./app/components/settings/settingsSaga";

// @ts-ignore
document?.getElementById("app-manifest")?.setAttribute('href', SMARTPUSH_API_BASE_URL  + '/manifest');

const listEnvToInitializeSentry = ["prod", "preprod"]
// @ts-ignore
const initializeSentry = listEnvToInitializeSentry.includes(process.env.REACT_APP_APP_ENV);

Sentry.init({
  dsn: initializeSentry ? "https://b05a02265f334467a66e24e760ceeddb@o510672.ingest.sentry.io/6066233" : undefined,
  integrations: [new BrowserTracing({
    tracingOrigins: ["smartpush"],
    shouldCreateSpanForRequest(url: string): boolean {
      return !url.match(/auth(-preprod|-prod|-newprod)?\.(smartpush|phoenix)/);
    },
    idleTimeout: 10000,
    heartbeatInterval: 10000
  }) as unknown as Integration],
  tracesSampleRate: 0.2,
  environment: initializeSentry ? process.env.REACT_APP_APP_ENV : undefined,
});

const ThemeSelector = () => {
  useInjectSaga({ key: 'partnerSettings', saga: settingsSaga });
  const partner = getPartnerFromHostname();
  const [Component, setComponent] = useState<any>(null);
  const partnerSettings = useSelector(makeSelectPartnerSettings());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(callSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let path,
    primaryLink,
    bannerBackgroundPrimary,
    bannerBackgroundSecondary,
    bannerBackgroundThird,
    white = '#ffffff',
    black = '#000000',
    blackLight = '#232323',
    grey = '#a3a3a3',
    greyCategory = '#a6b3c6',
    greyDarker = '#7e7e7e',
    greyLighter = '#f0f0f0',
    greyLighter2 = '#878b8d',
    greyLighter3 = '#c6cdd3',
    error = '#d32f2f',
    errorDarker = '#b71c1c',
    primary = '#dd054a',
    primaryDarker = '#c10640',
    primaryLighter = '#339fab',
    greyPrimaryLighter = '#d6d6d6',
    secondary = '#61cd8d',
    secondaryDarker = '#52a46e',
    brown = '#160007',
    greyLight = '#e5e6e6',
    input = '#e1e9ec';
    switch (partner) {
      case 'localhost':
      case 'phoenix':
        path = 'phoenix';
        primaryLink = '#f1054a';
        bannerBackgroundPrimary = '#f1054a';
        bannerBackgroundSecondary = '#e11c42';
        bannerBackgroundThird = '#FFC200';
        break;
      case 'reducavenue':
        path = 'reducavenue';
        primaryLink = '#f1054a';
        bannerBackgroundPrimary = '#f1054a';
        bannerBackgroundSecondary = '#e11c42';
        bannerBackgroundThird = '#FFC200';
        break;
      case 'faplus':
        path = 'adlp';
        primary = '#0060a9';
        primaryLink = '#00599e';
        primaryDarker = '#00599e';
        bannerBackgroundPrimary = '#E51E28';
        bannerBackgroundSecondary = '#E51E28';
        bannerBackgroundThird = '#E51E28';
        break;
      case 'studlife':
      case 'smerra':
        path = 'smerra';
        primaryLink = '#00599e';
        primary = '#0097d7';
        primaryDarker = '#00599e';
        bannerBackgroundPrimary = '#0097d7';
        bannerBackgroundSecondary = '#e2007a';
        bannerBackgroundThird = '#e2007a';
        break;
      case 'ace':
        path = 'ace';
        primaryLink = '#D91828';
        primary = '#D91828';
        primaryDarker = '#D91828';
        bannerBackgroundPrimary = '#D91828';
        bannerBackgroundSecondary = '#A71618';
        bannerBackgroundThird = '#FFFFFF';
        break;
      case 'studentbeans':
        path = 'studentsBeans';
        primaryLink = '#50C3CD';
        primary = '#50C3CD';
        primaryDarker = '#0000D7';
        bannerBackgroundPrimary = '#04D0D5';
        bannerBackgroundSecondary = '#6523E3';
        bannerBackgroundThird = '#B393FF';
        break;
      case 'edf':
          path = 'edf';
          primaryLink = '#00599e';
          primary = '#1057c8';
          primaryDarker = '#001A70';
          secondary = '#666666';
          secondaryDarker = '#2A3030'
          break;
      case 'bconnect':
        path = 'bconnect';
        primaryLink = '#f1054a';
        bannerBackgroundPrimary = '#f1054a';
        bannerBackgroundSecondary = '#e11c42';
        bannerBackgroundThird = '#FFC200';
        break;
    }
    const root = document.documentElement;
    root.style.setProperty('--primary', primary ?? null);
    root.style.setProperty('--primary-link', primaryLink ?? null);
    root.style.setProperty('--primary-darker', primaryDarker ?? null);
    root.style.setProperty('--banner-background-primary', bannerBackgroundPrimary ?? null);
    root.style.setProperty('--banner-background-secondary', bannerBackgroundSecondary ?? null);
    root.style.setProperty('--banner-background-third', bannerBackgroundThird ?? null);
    root.style.setProperty('--white', white ?? null);
    root.style.setProperty('--black', black ?? null);
    root.style.setProperty('--blackLight', blackLight ?? null);
    root.style.setProperty('--grey', grey ?? null);
    root.style.setProperty('--greyCategory', greyCategory ?? null);
    root.style.setProperty('--greyDarker', greyDarker ?? null);
    root.style.setProperty('--greyLighter', greyLighter ?? null);
    root.style.setProperty('--greyLighter2', greyLighter2 ?? null);
    root.style.setProperty('--greyLighter3', greyLighter3 ?? null);
    root.style.setProperty('--error', error ?? null);
    root.style.setProperty('--errorDarker', errorDarker ?? null);
    root.style.setProperty('--primaryLighter', primaryLighter ?? null);
    root.style.setProperty('--greyPrimaryLighter', greyPrimaryLighter ?? null);
    root.style.setProperty('--secondary', secondary ?? null);
    root.style.setProperty('--secondaryDarker', secondaryDarker ?? null);
    root.style.setProperty('--brown', brown ?? null);
    root.style.setProperty('--greyLight', greyLight ?? null);
    root.style.setProperty('--input', input ?? null);

      import('./partners/'+path).then(() => {
      const App = React.lazy(() => import('./App'))
      setComponent(App);
    });
  }, [partner])

  return (
    <>
      <React.Suspense fallback={<></>}>
        {!partnerSettings?.id && (
          <div className="containerInitLoader">
            <CircularProgress size={40} />
          </div>
        )}
        {(partnerSettings?.id && Component) && <Component/>}
      </React.Suspense>
    </>
  )
}

ReactDOM.render(
    <Provider store={store} >
        <ConnectedRouter history={history}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                  <ThemeSelector/>
                </MuiThemeProvider>
            </StylesProvider>
        </ConnectedRouter>
    </Provider>
    ,
    document.getElementById('smartpush')
);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
