import {call, put, takeLatest} from "redux-saga/effects";
import {CALL_SETTINGS} from "./constants";
import {SMARTPUSH_API_URL_PARTNER_SETTINGS_V2, SmartpushAPI} from "@smartpush-front/api";
import {callSettingsError, callSettingsSuccess} from "./actions";

export function* callSettings() {
  try {
    const {data} = yield call(
      SmartpushAPI.get,
      {
        url: SMARTPUSH_API_URL_PARTNER_SETTINGS_V2,
      }
    );

    yield put(callSettingsSuccess(data));
  } catch (e) {
    yield put(callSettingsError(e));
  }
}

export default function* settingsSaga() {
  yield takeLatest(CALL_SETTINGS, callSettings);
}
