const settings = {
  partnerName: 'Heyme',
  logoFile: 'heyme/images/logo-heyme.png',
  favicon: 'heyme/images/favicon.png',
  titleNavigation: 'Heyme',
  links: {
    helpCenter : 'https://heyme-myreduc.zendesk.com/hc/fr',
    legalMentions : 'https://help.smartpush.fr/heyme/mentions/',
    termsOfUse: 'https://help.smartpush.fr/heyme/cgs/',
    cookiesPolicy :'https://help.smartpush.fr/heyme/cookies/',
    privacyPolicy : 'https://help.smartpush.fr/heyme/privacy/',
    discountCoupons : 'https://help.smartpush.fr/heyme/conditions-particulieres-bons-dachat/',
    cashbackCoupons : 'https://help.smartpush.fr/heyme/conditions-particulieres-offres-cashback/',
    offerCoupons : 'https://help.smartpush.fr/heyme/conditions-particulieres-offres-coupons/',
    contact : 'mailto:heymemyreduc@smartpush.fr',
    support : 'mailto:heymemyreduc@smartpush.fr',
    textCarousel: true,
    carousel1: "https://media.smartpush.fr/data/images/logos.webp",
    carousel2: "https://media.smartpush.fr/data/images/thinking.jpg",
    carousel3: "https://media.smartpush.fr/data/images/lock.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/images/whoareyou.jpg",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/images/preferredBrands.jpeg",
    onBoardingHabits: "https://media.smartpush.fr/data/images/habits.jpeg",
    onBoardingProjects: "https://media.smartpush.fr/data/images/forgot-password.webp",
    boosterVideo: "https://heyme.smartpush.fr/data/partners/heyme/videos/HEYME-BOOSTERS.mp4"
  },
  styles: {
    primaryLink: "#207CF3",
    bannerBackgroundPrimary: "#f1054a",
    bannerBackgroundSecondary: "#e11c42",
    bannerBackgroundThird: "#FFC200",
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
    realm: "heyme",
    clientId: "app",
    clientSecret: null,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: false,
    smartAccount: true,
    onboarding: true,
    registration: true,
    analytics: {
      gtm: false ,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/heyme/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/heyme/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/heyme/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/heyme/images/offer-types/operation-mode-shop-coupon.png",
    }
  },
  widgetUrls: {
    dev: 'https://heyme.widget.dev.smartpush.fr:4200',
    preprod: 'https://heyme-widget-preprod.smartpush.fr',
    recette1: 'https://heyme.widget.recette1.smartpush.fr',
    recette2: 'https://heyme.widget.recette2.smartpush.fr',
    recette3: 'https://heyme.widget.recette3.smartpush.fr',
    prod: 'https://heyme-widget-prod.smartpush.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 20,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_2XwJJUle.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 26,
      srcUrl: 'https://analytics.smartpush.fr/js/container_VcYArsSW.js'
    }
  }
}

export default settings;
