import { getPartnerGlobalSettings } from './partners';
import Keycloak from "keycloak-js";

export const defaultColor = {
  white: '#FFFFFF',
  black: '#000000',
  blackLight: '#232323',
  grey: '#a3a3a3',
  greyCategory: "#a6b3c6",
  greyDarker: '#7e7e7e',
  greyLighter: '#f0f0f0',
  greyLighter2: '#878B8D',
  greyLighter3: '#c6cdd3',
  error: '#d32f2f',
  errorDarker: '#b71c1c',
  primary: '#DD054A',
  primaryDarker: '#c10640',
  primaryLighter: '#339FAB',
  greyPrimaryLighter: '#d6d6d6',
  secondary: '#61cd8d',
  secondaryDarker: '#52A46E',
  brown: '#160007',
  greyLight: '#e5e6e6',
  input: '#E1E9EC',
};

export const COLORS = { ...defaultColor, ...getPartnerGlobalSettings().styles };
export const SETTINGS = getPartnerGlobalSettings();


export const FORMS_STYLES = {
  borderRadiusTextField: 5,
  borderRadiusTextFieldXs: 5,
};
