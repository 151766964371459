import React from "react";
import {Typography, ButtonBase} from "@material-ui/core";
import {ImageService} from "../../../../../apps/phoenix/src/utils/imageUtil";

const settings = {
  partnerName: 'b.connect',
  logoFile: 'bconnect/images/phoenix-by-bconnect-logo.webp',
  favicon: 'bconnect/images/favicon.webp',
  titleNavigation: 'Phoenix',
  catchphrase: "Phoenix et b.connect s'associent pour vous simplifier la vie et gagner en pouvoir d'achat !",
  links: {
    helpCenter : 'https://phoenix-by-bconnect.zendesk.com/hc/fr',
    legalMentions : 'https://help.smartpush.fr/bconnect/mentions/',
    termsOfUse: 'https://help.smartpush.fr/bconnect/cgs/',
    cookiesPolicy : 'https://help.smartpush.fr/bconnect/cookies/',
    privacyPolicy : 'https://help.smartpush.fr/bconnect/privacy/',
    discountCoupons : 'https://help.smartpush.fr/bconnect/conditions-particulieres-bons-dachat/',
    cashbackCoupons : 'https://help.smartpush.fr/bconnect/conditions-particulieres-offres-cashback/',
    offerCoupons : 'https://help.smartpush.fr/bconnect/conditions-particulieres-offres-coupons/',
    contact : 'mailto:support.phoenix-by-bconnect@smartpush.io',
    support : 'mailto:support.phoenix-by-bconnect@smartpush.io?subject=Support',
    textCarousel: true,
    carousel1: "https://media.smartpush.fr/data/images/logos.webp",
    carousel2: "https://media.smartpush.fr/data/images/thinking.jpg",
    carousel3: "https://media.smartpush.fr/data/images/lock.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/images/whoareyou.jpg",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/images/preferredBrands.jpeg",
    onBoardingHabits: "https://media.smartpush.fr/data/images/habits.jpeg",
    onBoardingProjects: "https://media.smartpush.fr/data/images/forgot-password.webp",
    boosterVideo: "https://media.smartpush.fr/data/partners/bconnect/videos/BCONNECT-BOOSTERS.mp4"
  },
  styles: {
    primaryLink: "#f1054a",
    bannerBackgroundPrimary: "#f1054a",
    bannerBackgroundSecondary: "#e11c42",
    bannerBackgroundThird: "#FFC200",
    bannerBackgroundColors: [
      '#E93D89',
      '#E20716',
      '#F49423',
      '#F4FF20',
    ]
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
    realm: "bconnect",
    clientId: "app",
    clientSecret: process.env.REACT_APP_IAM_API_BCONNECT_CLIENT_SECRET,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false,
    scope: "openid risk_score"
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: true,
    smartAccount: false,
    onboarding: true,
    registration: true,
    analytics: {
      gtm: false,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/phoenix/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/phoenix/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/phoenix/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/phoenix/images/offer-types/operation-mode-shop-coupon.png",
    },
    authButtons: ({ signInAction }) => {
      return <div style={{ textAlign: 'center', padding: 30 }}>
        <Typography variant={'body1'} style={{ marginBottom: 30, fontFamily: 'ProximaNovaBold' }}>
          Connectez-vous ou créez votre compte en un seul clic
        </Typography>

        <ButtonBase
          component="div"
          onClick={signInAction}
          style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 35, borderBottomLeftRadius: 35 }}>
          <img width={200} src={`${ImageService.getBaseUrlMedia()}/data/partners/bconnect/images/bconnect-button.png`} alt={"B.Connect"} />
        </ButtonBase>

        <Typography variant={'body1'} style={{ marginTop: 30, fontFamily: 'ProximaNovaBold' }}>
          Simple | Rapide | Ultra sécurisé
        </Typography>
      </div>
    }
  },
  widgetUrls: {
    dev: 'https://bconnect.widget.dev.smartpush.fr:4200',
    preprod: 'https://bconnect-widget-preprod.smartpush.fr',
    recette1: 'https://bconnect.widget.recette1.smartpush.fr',
    recette2: 'https://bconnect.widget.recette2.smartpush.fr',
    recette3: 'https://bconnect.widget.recette3.smartpush.fr',
    prod: 'https://bconnect.widget.phoenix.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 18,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_G7OJHmTZ.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 25,
      srcUrl: 'https://analytics.smartpush.fr/js/container_TUFYdynJ.js'
    }
  }
}

export default settings;
