import {CALL_SETTINGS, CALL_SETTINGS_ERROR, CALL_SETTINGS_SUCCESS} from "./constants";

export function callSettings() {
  return {
    type: CALL_SETTINGS
  }
}

export function callSettingsSuccess(settings: any) {
  return {
    type: CALL_SETTINGS_SUCCESS,
    settings
  }
}

export function callSettingsError(err:any) {
  return {
    type: CALL_SETTINGS_ERROR,
    err
  }
}
