export const SUBMIT_LOGIN         = 'SmartPush/User/SUBMIT_LOGIN';
export const SUBMIT_LOGIN_SUCCESS = 'SmartPush/User/SUBMIT_LOGIN_SUCCESS';
export const SUBMIT_LOGIN_ERROR   = 'SmartPush/User/SUBMIT_LOGIN_ERROR';

export const GET_USER_DATA_WITH_TOKEN         = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN';
export const GET_USER_DATA_WITH_TOKEN_SUCCESS = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN_SUCCESS';
export const GET_USER_DATA_WITH_TOKEN_ERROR   = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN_ERROR';
export const USER_LOG_OUT                     = 'SmartPush/User/LOG_OUT';
export const CLEAR_DATA                       = 'SmartPush/User/CLEAR_DATA';
export const SNACKBAR_SYSTEM_OPEN             = 'SmartPush/User/SNACKBAR_SYSTEM_OPEN';
export const SNACKBAR_SYSTEM_CLOSE            = 'SmartPush/User/SNACKBAR_SYSTEM_CLOSE';
export const IS_NAVIGATION_CGU_OPEN           = 'SmartPush/User/IS_NAVIGATION_CGU_OPEN';
export const IS_LEGAL_MENTIONS_OPEN           = 'SmartPush/User/IS_LEGAL_MENTIONS_OPEN';

export const SUBMIT_REFRESH_TOKEN         = 'SmartPush/User/SUBMIT_REFRESH_TOKEN';
export const SUBMIT_REFRESH_TOKEN_SUCCESS = 'SmartPush/User/SUBMIT_REFRESH_TOKEN_SUCCESS';
export const SUBMIT_REFRESH_TOKEN_ERROR   = 'SmartPush/User/SUBMIT_REFRESH_TOKEN_ERROR';

export const SUBMIT_PROFILE = 'SmartPush/User/SUBMIT_PROFILE';
export const SUBMIT_PROFILE_SUCCESS = 'SmartPush/User/SUBMIT_PROFILE_SUCCESS';
export const SUBMIT_PROFILE_ERROR = 'SmartPush/User/SUBMIT_PROFILE_ERROR';

export const USER_REGISTER         = 'SmartPush/User/USER_REGISTER';
export const USER_REGISTER_SUCCESS = 'SmartPush/User/USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR   = 'SmartPush/User/USER_REGISTER_ERROR';

export const GET_CASHBACK         = 'SmartPush/User/GET_CASHBACK';
export const GET_CASHBACK_SUCCESS = 'SmartPush/User/GET_CASHBACK_SUCCESS';
export const GET_CASHBACK_ERROR   = 'SmartPush/User/GET_CASHBACK_ERROR';

export const CASHBACK_MODAL_SUCCESS_OPEN  = 'SmartPush/User/CASHBACK_MODAL_SUCCESS_OPEN';
export const CASHBACK_MODAL_SUCCESS_CLOSE = 'SmartPush/User/CASHBACK_MODAL_SUCCESS_CLOSE';

export const CASHBACK_MODAL_OPEN        = 'SmartPush/User/CASHBACK_MODAL_OPEN';
export const CASHBACK_MODAL_CLOSE       = 'SmartPush/User/CASHBACK_MODAL_CLOSE';
export const CASHBACK_MODAL_ERROR       = 'SmartPush/User/CASHBACK_MODAL_ERROR';
export const CLEAR_CASHBACK_MODAL_ERROR = 'SmartPush/User/CLEAR_CASHBACK_MODAL_ERROR';

export const GET_ORDERS         = 'SmartPush/User/GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'SmartPush/User/GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR   = 'SmartPush/User/GET_ORDERS_ERROR';

export const GET_ORDER         = 'SmartPush/User/GET_ORDER';
export const GET_ORDER_SUCCESS = 'SmartPush/User/GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR   = 'SmartPush/User/GET_ORDER_ERROR';

export const PUT_USER_DATA_PROFILE         = 'SmartPush/User/PUT_USER_DATA_PROFILE';
export const PUT_USER_DATA_PROFILE_SUCCESS = 'SmartPush/User/PUT_USER_DATA_PROFILE_SUCCESS';
export const PUT_USER_DATA_PROFILE_ERROR   = 'SmartPush/User/PUT_USER_DATA_PROFILE_ERROR';

//PROVIDERS

export const I_FEEL_GOOD_PROVIDER = '583ff1a9-a84a-4312-8cc0-605446260961';

// KEYCLOAK
export const SUBMIT_REFRESH_TOKEN_KEYCLOAK = 'SmartPush/User/SUBMIT_REFRESH_TOKEN_KEYCLOAK';
export const SUBMIT_REFRESH_TOKEN_KEYCLOAK_SUCCESS = 'SmartPush/User/SUBMIT_REFRESH_TOKEN_KEYCLOAK_SUCCESS';
export const SUBMIT_REFRESH_TOKEN_KEYCLOAK_ERROR = 'SmartPush/User/SUBMIT_REFRESH_TOKEN_KEYCLOAK_ERROR';
export const SUBMIT_LOGIN_SUCCESS_KEYCLOAK = 'SmartPush/User/SUBMIT_LOGIN_SUCCESS_KEYCLOAK';

export const GET_LOCATION_OFFER_BEFORE_LOGIN = 'SmartPush/User/GET_LOCATION_OFFER_BEFORE_LOGIN';
export const GET_LOCATION_OFFER_BEFORE_PAYMENT_ORDER = 'SmartPush/User/GET_LOCATION_OFFER_BEFORE_PAYMENT_ORDER';
export const SET_OFFER_DISTANCE = "SmartPush/OffersPage/SET_OFFER_DISTANCE";

export const SUBMIT_CONSENT = "SmartPush/User/SUBMIT_CONSENT";

export const INIT_APP_STATUS = "SmartPush/INIT_APP_STATUS";
export const INIT_APP_TOKEN_RECEIVED = "SmartPush/INIT_APP_TOKEN_RECEIVED";

// Sidebar
export const TOGGLE_SIDEBAR_LEFT = "SmartPush/TOGGLE_SIDEBAR_LEFT";
export const TOGGLE_SIDEBAR_RIGHT = "SmartPush/TOGGLE_SIDEBAR_RIGHT";
