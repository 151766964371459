import {ImageService} from "../../../../apps/phoenix/src/utils/imageUtil";

export const buildUrl = (url: string): string => {
  if (!url) { return ''; }
  if (/^https?:\/\/.*$/.test(url)) {
    return url;
  } else {
    const origin = ImageService.getBaseUrlMedia();
    return url.substr(0, 1) === '/' ? origin + url : `${origin}/${url}`;
  }
}
