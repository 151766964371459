const settings = {
  partnerName: 'StudentBeans',
  partnerNameOfferPage: 'StudentBeans',
  logoFile: 'studentbeans/images/phoenix-by-studentbeans-Logo.png',
  favicon: 'phoenix/images/favicon.png',
  familiarity: true,
  vipCode: 'PX3020A1',
  titleNavigation: 'phoenix by StudentBeans',
  links: {
    helpCenter : 'https://phoenix-studentbeans.zendesk.com/hc/fr',
    legalMentions : 'https://help.smartpush.fr/studentbeans/mentions-legales/',
    termsOfUse: 'https://help.smartpush.fr/studentbeans/cgs-smartrewards/',
    cookiesPolicy :'https://help.smartpush.fr/studentbeans/politique-de-cookies/',
    privacyPolicy : 'https://help.smartpush.fr/studentbeans/confidentialite/',
    discountCoupons : 'https://help.smartpush.fr/studentbeans/conditions-particulieres-offres-bons-dachat/',
    contact : 'mailto:support.phoenix-by-studentbeans@smartpush.fr',
    support : 'mailto:support.phoenix-by-studentbeans@smartpush.fr',
    textCarousel: false,
    carousel1: "https://media.smartpush.fr/data/partners/studentbeans/homescreens/1325x934_image_1.webp",
    carousel2: "https://media.smartpush.fr/data/partners/studentbeans/homescreens/1325x934_image_2.webp",
    carousel3: "https://media.smartpush.fr/data/partners/studentbeans/homescreens/1325x934_image_3.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/partners/studentbeans/homescreens/1325x934_image_1.webp",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/partners/studentbeans/homescreens/1325x934_image_2.webp",
    onBoardingHabits: "https://media.smartpush.fr/data/partners/studentbeans/homescreens/1325x934_image_3.webp",
    onBoardingProjects: "https://media.smartpush.fr/data/partners/studentbeans/homescreens/1325x934_image_1.webp"
  },
  styles: {
    primaryLink: '#50C3CD',
    primary: '#50C3CD',
    primaryDarker: '#4E99CF',
    bannerBackgroundPrimary: '#04D0D5',
    bannerBackgroundSecondary: '#6523E3',
    bannerBackgroundThird: '#B393FF',
    forgotPasswordBackgroundImage: 'data/images/1325x934_image_1.webp',
    registerBackgroundImage: 'data/images/1325x934_image_1.webp',
    registeredBackgroundImage: 'data/images/1325x934_image_1.webp',
    cguBackgroundImage: 'data/images/1325x934_image_1.webp'
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_PHOENIX || process.env.NX_BASE_URL_IAM_DOMAIN_PHOENIX,
    realm: "studentbeans",
    clientId: "app",
    clientSecret: process.env.REACT_APP_IAM_API_STUDENT_BEANS_CLIENT_SECRET,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: true,
    smartAccount: false,
    onboarding: true,
    registration: true,
    analytics: {
      gtm: true ,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/studentbeans/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/studentbeans/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/studentbeans/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/studentbeans/images/offer-types/operation-mode-shop-coupon.png",
    }
  },
  widgetUrls: {
    dev: 'https://studentbeans.widget.dev.phoenix.fr:4200',
    preprod: 'https://studentbeans-widget-preprod.smartpush.fr',
    recette1: 'https://studentbeans.widget.recette1.smartpush.fr',
    recette2: 'https://studentbeans.widget.recette2.smartpush.fr',
    recette3: 'https://studentbeans.widget.recette3.smartpush.fr',
    prod: 'https://studentbeans.widget.phoenix.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 8,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_gx25a17o.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 13,
      srcUrl: 'https://analytics.smartpush.fr/js/container_GEaK6Pp2.js'
    }
  }
}

export default settings;
