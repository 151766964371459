/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
import produce from 'immer';
import { get } from 'local-storage';
import {
  CLEAR_DATA,
  GET_USER_DATA_WITH_TOKEN,
  GET_USER_DATA_WITH_TOKEN_ERROR,
  GET_USER_DATA_WITH_TOKEN_SUCCESS,
  IS_NAVIGATION_CGU_OPEN,
  SNACKBAR_SYSTEM_CLOSE,
  SNACKBAR_SYSTEM_OPEN,
  SUBMIT_LOGIN,
  SUBMIT_LOGIN_ERROR,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_REFRESH_TOKEN,
  SUBMIT_REFRESH_TOKEN_ERROR,
  SUBMIT_REFRESH_TOKEN_SUCCESS,
  USER_REGISTER,
  USER_REGISTER_ERROR,
  USER_REGISTER_SUCCESS,
  GET_CASHBACK,
  GET_CASHBACK_ERROR,
  GET_CASHBACK_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_ERROR,
  GET_ORDERS_SUCCESS,
  GET_ORDER,
  GET_ORDER_ERROR,
  GET_ORDER_SUCCESS,
  PUT_USER_DATA_PROFILE,
  PUT_USER_DATA_PROFILE_ERROR,
  PUT_USER_DATA_PROFILE_SUCCESS,
  CASHBACK_MODAL_SUCCESS_CLOSE,
  CASHBACK_MODAL_SUCCESS_OPEN,
  CASHBACK_MODAL_CLOSE,
  CASHBACK_MODAL_OPEN,
  CASHBACK_MODAL_ERROR,
  CLEAR_CASHBACK_MODAL_ERROR,
  IS_LEGAL_MENTIONS_OPEN,
  SUBMIT_PROFILE,
  SUBMIT_PROFILE_SUCCESS,
  SUBMIT_PROFILE_ERROR,
  GET_LOCATION_OFFER_BEFORE_LOGIN,
  GET_LOCATION_OFFER_BEFORE_PAYMENT_ORDER,
  SET_OFFER_DISTANCE, INIT_APP_STATUS, INIT_APP_TOKEN_RECEIVED, TOGGLE_SIDEBAR_LEFT, TOGGLE_SIDEBAR_RIGHT,
} from '../constants/globalConstants';
import { DELETE_USER_DATA_ERROR, DELETE_USER_DATA_SUCCESS, USER_CONFIRM_REGISTRATION, USER_CONFIRM_REGISTRATION_ERROR, USER_CONFIRM_REGISTRATION_SUCCESS } from '../../../../../apps/phoenix/src/app/pages/LoginPage/constants';
import {
  CALL_CREDENTIAL_TOKEN_FRONT_ERROR, CALL_CREDENTIAL_TOKEN_FRONT_SUCCESS,
  CALL_TOKEN_EXCHANGE_FRONT, CALL_TOKEN_EXCHANGE_FRONT_ERROR,
  CALL_TOKEN_EXCHANGE_FRONT_SUCCESS,
} from '../../../../../apps/phoenix/src/app/components/tokens/constants';
import {
  CALL_SETTINGS_ERROR,
  CALL_SETTINGS_SUCCESS
} from "../../../../../apps/phoenix/src/app/components/settings/constants";

export const LS_OAUTH_TOKENS                = 'SmartpushOauthTokensFront';
export const LS_CREDENTIAL_TOKEN            = 'SmartpushCredentialTokenFront';
export const LS_CREDENTIAL_TOKEN_KEY        = 'CredentialTokenFront';
export const LS_OAUTH_ACCESS_TOKEN_KEY      = 'AccessTokenFront';
export const LS_OAUTH_REFRESH_TOKEN_KEY     = 'RefreshTokenFront';
export const LS_KEYCLOAK_TOKENS             = 'KeyCloakOauthTokens'
export const LS_KEYCLOAK_ACCESS_TOKEN_KEY   = 'AccessTokenKeyCloak';
export const LS_KEYCLOAK_REFRESH_TOKEN_KEY  = 'RefreshTokenKeyCloak';
export const LS_KEYCLOAK_RESPONSE_TOKEN_KEY = 'ResponseKeyCloak';
export const LS_IFRAME_TOKEN_KEY  = 'token';
export const LS_IFRAME_TOKEN  = 'IframeToken';


export interface Tokens {
  [LS_OAUTH_ACCESS_TOKEN_KEY]: string
  [LS_OAUTH_REFRESH_TOKEN_KEY]: string
  [LS_KEYCLOAK_ACCESS_TOKEN_KEY]: string
  [LS_KEYCLOAK_REFRESH_TOKEN_KEY]: string
}

export interface Token {
  [LS_CREDENTIAL_TOKEN_KEY]: string
}

export interface IframeToken {
  [LS_IFRAME_TOKEN_KEY]: string
}


// The initial state of the App
export const globalInitialState: GlobalInitialStateInterface = {
  loading            : false,
  userRegisterLoading: false,
  userRegisterErrors : {},
  userRegisterSuccess : false,
  userCguLoading : false,
  credentialToken : '',
  iframeToken : '',
  partnerSettings: {},
  auth               : {
    accessTokenKeyCloak : '',
    refreshTokenKeyCloak : '',
    responseKeyCloak: '',
    error   : undefined,
    appIsReady: false,
    tokenReceived: false,
    snackbar: {
      open   : false,
      type   : null,
      content: null,
    },
    userData: {
      confirmRegistration : {
        loading: false,
        error: null
      },
      accessToken         : '',
      refreshToken        : '',
      cashback            : {},
      cashbackModal       : false,
      cashbackModalError  : {},
      cashbackModalSuccess: false,
      orders              : [],
      order               : {},
      loadingProfile      : false,
      loadingOrders       : false,
      loadingCashback     : false,
      deleteUserError     : {},
      deleteUserSuccess : {},
      profile             : {
        reload : false,
        loading: false,
        error  : undefined,
        data   : {},
      },
    },
  },
  navigation         : {
    isCGUOpen: false,
    legalMentions: {
      open: false,
      src: ''
    },
    locationBeforeLogin: '',
    locationBeforePaymentOrder: ''
  },
  map: {
    zoom: 14,
    distance: 25000
  },
  sidebar: {
    leftIsVisible: false,
    rightIsVisible: false
  }
};

export interface UserDataProfileStateInterface {
  coordinates?: any,
  iban?: string,
  bic?: string
}

export interface GlobalInitialStateInterface {
  loading: boolean,
  userRegisterLoading: boolean,
  userRegisterErrors : {},
  userRegisterSuccess: boolean,
  userCguLoading: boolean,
  credentialToken : string,
  iframeToken : string,
  partnerSettings: {
    [key:string]: any
  },
  auth: {
    accessTokenKeyCloak : string,
    refreshTokenKeyCloak : string,
    responseKeyCloak: string,
    error?: any,
    appIsReady: boolean,
    tokenReceived: boolean,
    snackbar: {
      open: boolean,
      type: any,
      content: any
    },
    userData: {
      confirmRegistration: {
        loading: boolean,
        error: Error
      },
      accessToken: string,
      refreshToken: string,
      cashback: any,
      cashbackModal: boolean,
      cashbackModalError: any,
      cashbackModalSuccess: boolean,
      orders: any,
      order: any,
      loadingProfile: boolean,
      loadingOrders: boolean,
      loadingCashback: boolean,
      deleteUserError: any,
      deleteUserSuccess: any,
      profile: {
        reload: boolean,
        loading: boolean,
        error?: any,
        data: UserDataProfileStateInterface
      }
    }
  },
  navigation: {
    isCGUOpen: boolean,
    legalMentions: {
      src: string,
      open: boolean
    },
    locationBeforeLogin: string,
    locationBeforePaymentOrder:string
  },
  map: {
    zoom: number,
    distance: number
  },
  sidebar: {
    leftIsVisible: boolean,
    rightIsVisible: boolean
  }
}

const hydrateUserState = (): GlobalInitialStateInterface => {
  try {
    let credentialToken = '';
    let iframeToken = '';
    const savedTokens: Tokens = get(LS_OAUTH_TOKENS);
    const savedCredentialToken: Token = get(LS_CREDENTIAL_TOKEN);
    const savedIframeToken: IframeToken = get(LS_IFRAME_TOKEN);
    let accessToken           = '';
    let refreshToken          = '';
    if (savedCredentialToken) {
      credentialToken = savedCredentialToken[LS_CREDENTIAL_TOKEN_KEY]
    }
    if (savedTokens) {
      accessToken  = savedTokens[LS_OAUTH_ACCESS_TOKEN_KEY];
      refreshToken = savedTokens[LS_OAUTH_REFRESH_TOKEN_KEY];
    }
    if (savedIframeToken) {
      iframeToken = savedIframeToken[LS_IFRAME_TOKEN_KEY];
    }

    const savedKeyCloakTokens: Tokens = get(LS_KEYCLOAK_TOKENS);
    let accessTokenKeyCloak = '';
    let refreshTokenKeyCloak = '';
    let responseKeyCloak = '';
    if(savedKeyCloakTokens) {
      accessTokenKeyCloak = savedKeyCloakTokens[LS_KEYCLOAK_ACCESS_TOKEN_KEY];
      refreshTokenKeyCloak = savedKeyCloakTokens[LS_KEYCLOAK_REFRESH_TOKEN_KEY];
      responseKeyCloak = savedKeyCloakTokens[LS_KEYCLOAK_RESPONSE_TOKEN_KEY];
    }



    if (accessToken === '' && refreshToken === '' && credentialToken === '' && iframeToken === '' && accessTokenKeyCloak === '' && refreshTokenKeyCloak === '' && responseKeyCloak == '') {
      return globalInitialState;
    }

    return {
      loading            : false,
      userRegisterLoading: false,
      userCguLoading : false,
      userRegisterErrors : {},
      userRegisterSuccess : false,
      credentialToken: credentialToken,
      iframeToken: iframeToken,
      partnerSettings: null,
      auth               : {
        accessTokenKeyCloak: accessTokenKeyCloak,
        refreshTokenKeyCloak: refreshTokenKeyCloak,
        responseKeyCloak: responseKeyCloak,
        error   : undefined,
        appIsReady: false,
        tokenReceived: false,
        snackbar: {
          open   : false,
          type   : null,
          content: null,
        },
        userData: {
          confirmRegistration : {
            loading: false,
            error: null
          },
          accessToken         : accessToken,
          refreshToken        : refreshToken,
          cashback            : {},
          cashbackModal       : false,
          cashbackModalError  : false,
          cashbackModalSuccess: false,
          orders              : [],
          order               : {},
          loadingProfile      : false,
          loadingOrders       : false,
          loadingCashback     : false,
          deleteUserError     : {},
          deleteUserSuccess   : {},
          profile             : {
            reload: false,
            loading: false,
            error  : undefined,
            data   : {},
          },
        },
      },
      navigation         : {
        isCGUOpen: false,
        legalMentions: {
          open: false,
          src: ''
        },
        locationBeforeLogin: '',
        locationBeforePaymentOrder: ''
      },
      map: {
        zoom: 14,
        distance: 25000
      },
      sidebar: {
        leftIsVisible: false,
        rightIsVisible: false
      }
    };
  } catch (error) {
    return globalInitialState;
  }
};

function isEmpty(obj: any) {
  for(let key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const globalReducer = (state: GlobalInitialStateInterface = hydrateUserState(), action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case CALL_CREDENTIAL_TOKEN_FRONT_SUCCESS:
        draft.credentialToken = action.credentialToken;
        break;
      case CALL_CREDENTIAL_TOKEN_FRONT_ERROR:
        break;
      case CALL_TOKEN_EXCHANGE_FRONT:
        break;
      case CALL_TOKEN_EXCHANGE_FRONT_SUCCESS:
        draft.auth.accessTokenKeyCloak = action.accessTokenKeyCloak;
        draft.auth.refreshTokenKeyCloak = action.refreshTokenKeyCloak;
        draft.auth.responseKeyCloak = action.keycloakResponse;
        break;
      case CALL_TOKEN_EXCHANGE_FRONT_ERROR:
        break;
      case SUBMIT_PROFILE:
        draft.userCguLoading = true;
        break;
      case SUBMIT_PROFILE_SUCCESS:
        draft.userCguLoading = false;
        break;
      case SUBMIT_PROFILE_ERROR:
        draft.userCguLoading = false;
        break;
      case SUBMIT_LOGIN:
        draft.loading    = true;
        draft.auth.error = undefined;
        break;
      case SUBMIT_LOGIN_SUCCESS:
        draft.loading                    = false;
        draft.auth.userData.accessToken  = action.accessToken;
        draft.auth.userData.refreshToken = action.refreshToken;
        break;
      case SUBMIT_LOGIN_ERROR:
        draft.auth.error = action.error;
        draft.loading    = false;
        break;
      case GET_USER_DATA_WITH_TOKEN:
        draft.loading                       = true;
        draft.auth.userData.profile.loading = true;
        break;
      case GET_USER_DATA_WITH_TOKEN_SUCCESS:
        draft.loading                       = false;
        draft.auth.userData.profile.loading = false;
        draft.auth.userData.profile.data    = action.userData;
        break;
      case GET_USER_DATA_WITH_TOKEN_ERROR:
        draft.loading                     = false;
        draft.auth.userData.profile.error = action.error;
        break;
      case USER_REGISTER:
        draft.userRegisterLoading = true;
        draft.userRegisterErrors =  {};
        break;
      case USER_REGISTER_SUCCESS:
        draft.userRegisterLoading = false;
        draft.userRegisterSuccess = true;
        break;
      case USER_REGISTER_ERROR:
        draft.userRegisterLoading = false;
        draft.userRegisterErrors = action?.error ?? {};
        break;
      case PUT_USER_DATA_PROFILE:
        draft.auth.userData.loadingProfile = true;
        break;
      case PUT_USER_DATA_PROFILE_SUCCESS:
        draft.auth.userData.loadingProfile = false;
        draft.auth.userData.profile.data   = {
          ...action.user,
          coordinates: action.user.coordinates === null ? state.auth.userData.profile.data && state.auth.userData.profile.data.coordinates : action.user.coordinates,
        };
        break;
      case PUT_USER_DATA_PROFILE_ERROR:
        draft.auth.userData.loadingProfile = false;
        break;
      case CLEAR_DATA:
        return {
          ...globalInitialState,
          credentialToken: state.credentialToken
        }
      case SNACKBAR_SYSTEM_OPEN:
        draft.auth.snackbar.open    = true;
        draft.auth.snackbar.type    = action.typeOfAlert;
        draft.auth.snackbar.content = action.content;
        break;
      case SNACKBAR_SYSTEM_CLOSE:
        draft.auth.snackbar.open    = false;
        draft.auth.snackbar.type    = action.typeOfAlert;
        draft.auth.snackbar.content = action.content;
        break;
      case IS_NAVIGATION_CGU_OPEN:
        draft.navigation.isCGUOpen = !state.navigation.isCGUOpen;
        break;
      case IS_LEGAL_MENTIONS_OPEN:
        draft.navigation.legalMentions.open = !state.navigation.legalMentions.open;
        draft.navigation.legalMentions.src = action.src;
        break;
      case SUBMIT_REFRESH_TOKEN:
        draft.loading = true;
        break;
      case SUBMIT_REFRESH_TOKEN_SUCCESS:
        draft.loading                    = false;
        draft.auth.userData.accessToken  = action.accessToken;
        draft.auth.userData.refreshToken = action.refreshToken;
        break;
      case SUBMIT_REFRESH_TOKEN_ERROR:
        return globalInitialState;
      case GET_CASHBACK:
        draft.auth.userData.loadingCashback = true;
        break;
      case GET_CASHBACK_SUCCESS:
        draft.auth.userData.loadingCashback = false;
        // eslint-disable-next-line no-case-declarations
        const computeAmountForStatus        = (arr: any[], statuses: number[]) => {
          if (!arr) {
            arr = [];
          }
          return arr.reduce((accumulator, cashback) => {
            if (statuses.find(status => status === cashback.status_code)) {
              return accumulator + cashback.amount;
            } else {
              return accumulator;
            }
          }, 0);
        };
        draft.auth.userData.cashback        = {
          data         : action.cashBackData,
          amountPaid   : computeAmountForStatus(action.cashBackData, [ 4 ]),
          amountPending: computeAmountForStatus(action.cashBackData, [ 2, 1 ]),
          amountPaying : computeAmountForStatus(action.cashBackData, [ 6 ]),
          amountPayable: computeAmountForStatus(action.cashBackData, [ 3 ]),
        };
        break;
      case GET_CASHBACK_ERROR:
        draft.auth.userData.loadingCashback = false;
        draft.auth.userData.cashback        = {};
        break;
      case CASHBACK_MODAL_SUCCESS_OPEN:
        draft.auth.userData.cashback.cashbackModalSuccess = true;
        break;
      case CASHBACK_MODAL_SUCCESS_CLOSE:
        draft.auth.userData.cashback.cashbackModalSuccess = false;
        break;
      case CASHBACK_MODAL_OPEN:
        draft.auth.userData.cashback.cashbackModal = true;
        break;
      case CASHBACK_MODAL_CLOSE:
        draft.auth.userData.cashback.cashbackModal = false;
        break;
      case CASHBACK_MODAL_ERROR:
        let error: any = {};
        if (action.error?.error) {
          switch (action.error.error) {
            case'Wrong password provided on cashback payout request':
              error.password = {
                __errors: ['Le mot de passe fourni ne corresponds pas'],
              };
              break;

          }
        }
        draft.auth.userData.cashback.cashbackModalError = isEmpty(error) ? true : error;
        break;
      case CLEAR_CASHBACK_MODAL_ERROR:
        draft.auth.userData.cashback.cashbackModalError = false;
        break;
      case GET_ORDERS:
        draft.auth.userData.loadingOrders = true;
        break;
      case GET_ORDERS_SUCCESS:
        draft.auth.userData.loadingOrders = false;
        draft.auth.userData.orders        = action.ordersData;
        break;
      case GET_ORDERS_ERROR:
        draft.auth.userData.loadingOrders = false;
        draft.auth.userData.orders        = {};
        break;
      case GET_ORDER:
        draft.auth.userData.loadingOrders = true;
        break;
      case GET_ORDER_SUCCESS:
        draft.auth.userData.loadingOrders = false;
        draft.auth.userData.order         = action.orderData;
        break;
      case GET_ORDER_ERROR:
        draft.auth.userData.loadingOrders = false;
        draft.auth.userData.order         = {};
        break;

      case CALL_SETTINGS_SUCCESS:
        draft.partnerSettings = action?.settings;
        break;
      case CALL_SETTINGS_ERROR:
        draft.partnerSettings = {};
        break;

      case DELETE_USER_DATA_ERROR:
        draft.auth.userData.deleteUserError  = action.error;
        break;

      case DELETE_USER_DATA_SUCCESS:
        draft.auth.userData.deleteUserSuccess  = action.data;
      break;

      case USER_CONFIRM_REGISTRATION:
        draft.auth.userData.confirmRegistration = {
          loading: true,
          error: null
        }
        break;
      case USER_CONFIRM_REGISTRATION_SUCCESS:
        draft.auth.userData.confirmRegistration = {
          loading: false,
          error: null
        }
        break;
      case USER_CONFIRM_REGISTRATION_ERROR:
        draft.auth.userData.confirmRegistration = {
          loading: false,
          error: action.error
        }
        break;
      case GET_LOCATION_OFFER_BEFORE_LOGIN:
        draft.navigation.locationBeforeLogin = action.location
        break;
      case GET_LOCATION_OFFER_BEFORE_PAYMENT_ORDER:
        draft.navigation.locationBeforePaymentOrder = action.location;
        break;
      case SET_OFFER_DISTANCE:
        const actualDistance = state.map.distance;
        const diffZoom = state.map.zoom - action.zoom;
        let targetDistance
        if (diffZoom >= 1 && diffZoom !== 0) {
          targetDistance = actualDistance << diffZoom
        } else if(diffZoom <= 1 && diffZoom !== 0) {
          targetDistance = actualDistance >> Math.abs(diffZoom)
        } else {
          targetDistance = actualDistance;
        }
        draft.map.zoom = action.zoom;
        draft.map.distance = targetDistance;
        break;
      case INIT_APP_STATUS:
        draft.auth.appIsReady = action.isReady;
        break;
      case INIT_APP_TOKEN_RECEIVED:
        draft.auth.tokenReceived = action.isTokenReceived;
        break;
      case TOGGLE_SIDEBAR_LEFT:
        draft.sidebar.leftIsVisible = !state.sidebar.leftIsVisible;
        break;
      case TOGGLE_SIDEBAR_RIGHT:
        draft.sidebar.rightIsVisible = !state.sidebar.rightIsVisible;
        break;
    }
  });
