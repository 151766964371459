import React from "react";

const settings = {
  partnerName: 'Phoenix',
  favicon: 'phoenix/images/favicon.png',
  titleNavigation: 'Phoenix',
  links: {
    contact : 'mailto:support.phoenix@smartpush.fr',
    support : 'mailto:support.phoenix@smartpush.fr?subject=Support',
    textCarousel: true,
    carousel1: "https://media.smartpush.fr/data/images/logos.webp",
    carousel2: "https://media.smartpush.fr/data/images/thinking.jpg",
    carousel3: "https://media.smartpush.fr/data/images/lock.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/images/whoareyou.jpg",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/images/preferredBrands.jpeg",
    onBoardingHabits: "https://media.smartpush.fr/data/images/habits.jpeg",
    onBoardingProjects: "https://media.smartpush.fr/data/images/forgot-password.webp",
    boosterVideo: "https://media.smartpush.fr/data/partners/phoenix/videos/PHOENIX-BOOSTERS.mp4"
  },
  styles: {
    primaryLink: "#f1054a",
    bannerBackgroundPrimary: "#f1054a",
    bannerBackgroundSecondary: "#e11c42",
    bannerBackgroundThird: "#FFC200",
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
    realm: "phoenix",
    clientId: "app",
    clientSecret: process.env.REACT_APP_IAM_API_PHOENIX_CLIENT_SECRET,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    onboarding: true,
    registration: true,
    analytics: {
      matomo: true,
      iFrame: false
    }
  },
  specific: {},
  widgetUrls: {
    dev: 'https://phoenix.widget.dev.smartpush.fr:4200',
    preprod: 'https://phoenix-widget-preprod.smartpush.fr',
    recette1: 'https://phoenix.widget.recette1.smartpush.fr',
    recette2: 'https://phoenix.widget.recette2.smartpush.fr',
    recette3: 'https://phoenix.widget.recette3.smartpush.fr',
    prod: 'https://phoenix.widget.smartpush.fr',
  },
  bankWidgetUrls: {
    dev: 'https://phoenix.bank-widget.dev.smartpush.fr:4200',
    preprod: 'https://phoenix-bankwidget-preprod.smartpush.fr',
    recette1: 'https://phoenix.bank-widget.recette1.smartpush.fr',
    recette2: 'https://phoenix.bank-widget.recette2.smartpush.fr',
    recette3: 'https://phoenix.bank-widget.recette3.smartpush.fr',
    prod: 'https://phoenix.bank-widget.smartpush.fr',
  },
  matomo: {
    dev: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 1,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_3UuYl47u.js'
    },
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 1,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_3UuYl47u.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 12,
      srcUrl: 'https://analytics.smartpush.fr/js/container_WXzGvJlh.js'
    },
    customDimensions: {
      screen_category: {
        prod: 1,
        preprod: 4
      },
      filter_name: {
        prod: 2,
        preprod: 6
      },
      item_brand: {
        prod: 3,
        preprod: 12
      },
      item_provider: {
        prod: 4,
        preprod: 30
      },
      item_type: {
        prod: 5,
        preprod: 31
      },
      discount_type: {
        prod: 6,
        preprod: 15
      },
      discount_amount: {
        prod: 7,
        preprod: 16
      },
      currency: {
        prod: 8,
        preprod: 17
      },
      sign_up_step: {
        prod: 9,
        preprod: 20
      },
      profil_step: {
        prod: 10,
        preprod: 21
      },
      brand_id: {
        prod: 11,
        preprod: 27
      },
      brand_name: {
        prod: 12,
        preprod: 28
      }
    }
  }
}

export default settings;
