/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import { history}  from '../../lib/history';
import { globalReducer, GlobalInitialStateInterface} from './globalReducer';
// import offersReducer from "../../app/pages/OffersPage/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export interface InitialRootStateInterface {
    global: GlobalInitialStateInterface,
    router: RouterState
    offers: any,
    offerShopList: any
};

export function createReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        global: globalReducer,
        router: connectRouter(history),
        // offers: offersReducer,
        ...injectedReducers,
    });

    return rootReducer;
}
