const settings = {
  partnerName: 'Smerra',
  partnerNameOfferPage: 'La SMERRA',
  logoFile: 'smerra/images/Club_SMERRA_Studlife.png',
  favicon: 'smerra/images/favicon.png',
  titleNavigation: 'Smerra',
  links: {
    helpCenter : 'https://studlife.zendesk.com/hc/fr',
    legalMentions : 'https://help.smartpush.fr/smerra/mentions/',
    termsOfUse: 'https://help.smartpush.fr/smerra/cgs/',
    cookiesPolicy :'https://help.smartpush.fr/smerra/cookies/',
    privacyPolicy : 'https://help.smartpush.fr/smerra/privacy/',
    discountCoupons : 'https://help.smartpush.fr/smerra/conditions-particulieres-offres-coupons/',
    cashbackCoupons : 'https://help.smartpush.fr/smerra/conditions-particulieres-offres-cashback/',
    offerCoupons : 'https://help.smartpush.fr/smerra/conditions-particulieres-bons-dachat/',
    contact : 'mailto:support.studlife@smartpush.fr',
    support : 'mailto:support.studlife@smartpush.fr',
    textCarousel: false,
    carousel1: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife1.webp",
    carousel2: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife2.webp",
    carousel3: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife3.webp",
    carousel5: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife4.webp",
    carousel6: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife5.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife3.webp",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife5.webp",
    onBoardingHabits: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife2.webp",
    onBoardingProjects: "https://media.smartpush.fr/data/partners/smerra/homescreens/SMERRA-studlife1.webp"
  },
  styles: {
    primaryLink: '#00599e',
    primary: '#0097d7',
    primaryDarker: '#00599e',
    bannerBackgroundPrimary: '#E2007A',
    bannerBackgroundSecondary: '#E2007A',
    bannerBackgroundThird: '#E2007A'
  },
  api: {
    prefix: 'smerra'
  },
  auth: {
    sso: true,
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
    realm: "smerra",
    clientId: "app",
    clientSecret: process.env.REACT_APP_IAM_API_SMERRA_CLIENT_SECRET,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: false,
    smartAccount: false,
    registration: false,
    analytics: {
      gtm: true,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/smerra/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/smerra/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/smerra/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/smerra/images/offer-types/operation-mode-shop-coupon.png",
    }
  },
  widgetUrls: {
    dev: 'https://studlife.widget.dev.smartpush.fr:4200',
    preprod: 'https://smerra-widget-preprod.smartpush.fr',
    recette1: 'https://smerra.widget.recette1.smartpush.fr',
    recette2: 'https://smerra.widget.recette2.smartpush.fr',
    recette3: 'https://smerra.widget.recette3.smartpush.fr',
    prod: 'https://smerra.widget.smartpush.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 12,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_k2bTPs9p.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 22,
      srcUrl: 'https://analytics.smartpush.fr/js/container_rRyZ07tU.js'
    }
  }
}

export default settings;
