/**
 * LoginPage selectors
 */

import { createSelector } from 'reselect';
import {globalInitialState} from "@smartpush-front/store";

const selectGlobal = (state: any) => state.global || globalInitialState;

/** User token selector */
const makeSelectLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.loading,
    );
const makeSelectUserRegisterLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userRegisterLoading,
    );
const makeSelectUserRegisterErrors = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.userRegisterErrors,
  );
const makeSelectUserRegisterSuccess = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.userRegisterSuccess,
  );
const getToken = () =>
  createSelector(
      selectGlobal,
    globalState => globalState.auth.userData.accessToken,
  );
const makeSelectLoginError = () =>
    createSelector(
        selectGlobal,
      globalState => globalState.auth.error
    );

/** User profile selectors*/
const makeSelectUserData = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.data,
    );
const makeSelectUserDataCoordinates = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.data.coordinates,
    );
const makeSelectUserDataError = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.error
    );
const makeSelectUserDataLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.loading
    );

const makeSelectUserDataProfileloading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.loadingProfile
    );

const makeSelectUserDataOrderloading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.loadingOrders
    );
const makeSelectSnackBar = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.snackbar
    );
const makeSelectCGUOpen = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.navigation.isCGUOpen
    );

const makeSelectUserDeleteError = () =>
    createSelector(
      selectGlobal,
      globalState => globalState.auth.userData.deleteUserError
    )

const makeSelectUserRegistrationState = () =>
    createSelector(
      selectGlobal,
      globalState => globalState.auth.userData.confirmRegistration
    )

const makeSelectAuth = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth
  )

const makeSelectUserAuthData = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth.userData
  )

const makeSelectPartnerSettings = () =>
  createSelector(
    selectGlobal,
    globalState => {
      return globalState.partnerSettings;
    }
  )

export {
    selectGlobal,
    getToken,
    makeSelectLoading,
    makeSelectLoginError,
    makeSelectUserData,
    makeSelectUserDataError,
    makeSelectUserDataLoading,
    makeSelectUserDataProfileloading,
    makeSelectUserDataOrderloading,
    makeSelectUserDataCoordinates,
    makeSelectSnackBar,
    makeSelectCGUOpen,
    makeSelectUserRegisterLoading,
    makeSelectUserRegisterErrors,
    makeSelectUserRegisterSuccess,
    makeSelectUserDeleteError,
    makeSelectUserRegistrationState,
    makeSelectAuth,
    makeSelectUserAuthData,
    makeSelectPartnerSettings
};
