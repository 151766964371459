export enum ImageEntityFormats {
  FORMAT_WEBP_SQUARE = "swd",
  FORMAT_WEBP_MINI_SQUARE = "mswd",
  FORMAT_WEBP_RECTANGLE = "rwd",
  FORMAT_WEBP_WIDE_DESKTOP = "wwd",
  FORMAT_WEBP_VIGNETTE_DESKTOP = "vwd",
  FORMAT_WEBP_VIGNETTE_MOBILE = "vwm",
  FORMAT_WEBP_WIDE_DESKTOP_EDF = "wwd_edf",
  FORMAT_VIGNETTE_BRAND_NG = "vngwa",
  FORMAT_WIDE_NG_DESKTOP = "wngwd",
  FORMAT_WIDE_NG_MOBILE = "wngwm"
}

export interface ImageEntityFormat {
  extension: string,
  height: number,
  width: number,
  name: ImageEntityFormats
  path: string
}

export interface ImageEntity {
  "id": string,
  "formats": ImageEntityFormat[],
}

export class ImageService {
  public static getSingleImage = (images: ImageEntity[], targetFormat: ImageEntityFormats[]|string[]): ImageEntityFormat|undefined => {
    for (const target of targetFormat) {
      const result = ImageService.execute(images, target);

      if (result) {
        return result;
      }
    }
  }

  static getSomeImages = (images: ImageEntity[], formats: ImageEntityFormats[]|string[]): ImageEntityFormat[]|null => {
    const results: ImageEntityFormat[] = [];

    for (const target of formats) {
      const result = ImageService.execute(images, target);
      if (result) {
        results.push(result);
      }
    }

    return results.length > 0 ? results : null;
  }

  static hasTargetFormat = (images: ImageEntity[], targetFormat: string|ImageEntityFormats): boolean => {
    return !!ImageService.getSingleImage(images, [targetFormat]);
  }

  static getRectLogo = (entity: any) => {
    const images = entity?.images ?? [];

    const logo = ImageService.getSingleImage(
      images,
      [ImageEntityFormats.FORMAT_WEBP_RECTANGLE, ImageEntityFormats.FORMAT_WEBP_MINI_SQUARE]
    );

    return logo?.path ?? "";
  }

  static getSquareLogo = (entity: any, isMiniSquare: boolean) => {
    const images = entity?.images ?? [];

    const logo = ImageService.getSingleImage(
      images,
      [isMiniSquare ? ImageEntityFormats.FORMAT_WEBP_MINI_SQUARE : ImageEntityFormats.FORMAT_WEBP_SQUARE]
    );

    return logo?.path ?? "";
  }

  static getBaseUrlMedia = () => {
    return process.env.REACT_APP_CDN_MEDIA || process.env.NX_CDN_MEDIA;
  }

  private static execute = (images: ImageEntity[], target: ImageEntityFormats|string) => {
    for (const image of images) {
      for (const format of (image?.formats ?? [])) {
        if (format.name === target) {
          return format;
        }
      }
    }
  }
}
