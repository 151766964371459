/*
 * Login Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * config here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

/**
 * USER LOGIN
 *
 * @type {string}
 */

export const SUBMIT_LOGIN = 'SmartPush/User/SUBMIT_LOGIN';
export const SUBMIT_LOGIN_SUCCESS = 'SmartPush/User/SUBMIT_LOGIN_SUCCESS';
export const SUBMIT_LOGIN_ERROR = 'SmartPush/User/SUBMIT_LOGIN_ERROR';
export const GET_USER_DATA_WITH_TOKEN = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN';
export const GET_USER_DATA_WITH_TOKEN_SUCCESS = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN_SUCCESS';
export const GET_USER_DATA_WITH_TOKEN_ERROR = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN_ERROR';
export const USER_LOG_OUT = 'SmartPush/User/LOG_OUT';
export const CLEAR_DATA = 'SmartPush/User/CLEAR_DATA';
export const SNACKBAR_SYSTEM_OPEN = 'SmartPush/User/SNACKBAR_SYSTEM_OPEN';
export const SNACKBAR_SYSTEM_CLOSE = 'SmartPush/User/SNACKBAR_SYSTEM_CLOSE';

export const SUBMIT_REFRESH_TOKEN = 'SmartPush/User/SUBMIT_REFRESH_TOKEN';
export const SUBMIT_REFRESH_TOKEN_SUCCESS = 'SmartPush/User/SUBMIT_REFRESH_TOKEN_SUCCESS';
export const SUBMIT_REFRESH_TOKEN_ERROR = 'SmartPush/User/SUBMIT_REFRESH_TOKEN_ERROR';

export const DELETE_USER_DATA = 'SmartPush/User/DELETE_USER_DATA';
export const DELETE_USER_DATA_SUCCESS = 'SmartPush/User/DELETE_USER_DATA_SUCCESS';
export const DELETE_USER_DATA_ERROR = 'SmartPush/User/DELETE_USER_DATA_ERROR';

export const USER_SSO = 'SmartPush/User/USER_SSO';
export const USER_CONFIRM_REGISTRATION = 'SmartPush/User/CONFIRM_REGISTRATION';
export const USER_CONFIRM_REGISTRATION_SUCCESS = 'SmartPush/User/CONFIRM_REGISTRATION_SUCCESS';
export const USER_CONFIRM_REGISTRATION_ERROR = 'SmartPush/User/CONFIRM_REGISTRATION_ERROR';
