import axios, {AxiosPromise, AxiosResponse} from 'axios';
import { ApiDataResponse }  from './responses/ApiDataResponse';
import { ApiErrorResponse } from './responses/ApiErrorResponse';

import {store, userFailedLoggedWithRefreshToken} from '@smartpush-front/store';
import {SETTINGS} from "@smartpush-front/styles";
import * as Qs                  from 'qs';

import {
  SMARTPUSH_API_BASE_URL,
}                    from './apiConstants';

export interface RefreshTokenResponse {
  access_token: string;
  expiresIn: number;
  tokenType: string;
  scope?: string;
  refresh_token: string;
}

let pendingRefreshResponse: AxiosPromise<RefreshTokenResponse> | undefined;

interface AuthHeader {
  headers?: { [key: string]: string},
  params?: { [key: string]: string}
}

export interface RequestOptions {
  url: string;
  data?;
  addAuthHeader?: boolean;
  model?;
  config?: AuthHeader
}
export class SmartpushAPI {
  /**
   * @private
   *
   * @param config
   */
  static __addAuthHeader(config: AuthHeader) {
    config.headers               = config.headers || {};
    config.headers.Authorization = 'Bearer '+ config.headers?.Authorization;
  }

  /**
   * @private
   *
   * @param reason
   */
  static __catchError(reason) {
    let error = {
      details: null,
      error           : 'unknown_error',
      errorDescription: reason?.message ? reason.message : 'Unknown error',
      statusCode      : null,
      urlForward      : null
    };
    if (reason?.code) {
      error.statusCode = reason.code;
    } else if (reason?.response?.status) {
      error.statusCode = reason.response.status;
    } else {
      error.statusCode = 400;
    }
    if (reason?.response?.data) {
        error.urlForward = reason.response.data.urlForward ? reason.response.data.urlForward : null
        error.error = reason.response.data?.error;
        error.errorDescription = reason.response.data?.details;
    } else if (reason?.message) {
      error = {
        ...error,
        error           : reason.message,
        errorDescription: reason.message
      };
    }

    throw new ApiErrorResponse(error);
  }

  /**
   * @private
   *
   * @param method
   * @param options
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static __request(method: string, { url, data, config = {}, addAuthHeader = true, model }: RequestOptions) {
    if (addAuthHeader && config.headers?.Authorization?.length > 0) {
      SmartpushAPI.__addAuthHeader(config);
    }

    method = method.toLowerCase();

    let response;
    if ([ 'post', 'put', 'patch' ].indexOf(method) !== -1) {
      // POST, PUT and PATCH, DELETE methods must have data parameter
      response = axios[method](url, data, config);
    } else {
      // GET, HEAD methods does not have data parameter
      response = axios[method](url, config);
    }

    if (model) {
      response = response.then(response => this.__transformResponse(response, model));
    }

    return response.catch(this.__catchError);
  }

  /**
   * @private
   *
   * @param response
   * @param model
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static __transformResponse(response, model): ApiDataResponse | ApiErrorResponse {
    if (response.status === 200) {
      return new ApiDataResponse(response, model);
    }

    return new ApiErrorResponse(response);
  }

  /**
   * @param options
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static delete(options: RequestOptions) {
    return SmartpushAPI.__request('delete', options);
  }

  /**
   * @param options
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static get(options: RequestOptions) {
    return SmartpushAPI.__request('get', options);
  }

  /**
   * @param options
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static head(options: RequestOptions) {
    return SmartpushAPI.__request('head', options);
  }

  /**
   * @param options
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static patch(options: RequestOptions) {
    return SmartpushAPI.__request('patch', options);
  }

  /**
   * @param options
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static post(options: RequestOptions) {
    return SmartpushAPI.__request('post', options);
  }

  /**
   * @param options
   *
   * @returns {ApiErrorResponse|ApiDataResponse}
   */
  static put(options: RequestOptions) {
    return SmartpushAPI.__request('put', options);
  }

  /**
   * Undelrying Axios request is shared between callers:
   * - since the refresh token can only be used once, only the first request will succeed
   * - for performance reason: to avoid multiple concurrent requests failing with 401 at the same time and all requesting a new token (there is no grace period)
   * @param refreshToken
   */
  static async refreshAccessToken(
      refreshToken: string
  ): Promise<AxiosResponse<RefreshTokenResponse>> {
    // As per the Smartpush's documentation, we need to be authenticated using OAuth2 Client Credentials
    if (!pendingRefreshResponse) {
      pendingRefreshResponse = axios.post<RefreshTokenResponse>(
          SMARTPUSH_API_BASE_URL+'/token',
          {
            // eslint-disable-next-line @typescript-eslint/camelcase
            grant_type: "refresh_token",
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: SETTINGS.clientId,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_secret: SETTINGS.clientSecret,
            // eslint-disable-next-line @typescript-eslint/camelcase
            refresh_token: refreshToken
          },
          // Higher timeout for this request since its result is critical, server processing will render our refreshToken invalid, we absolutely need to get the new one
          { timeout: 30000 }
          // { timeout: 1000 } small timeout to test error case
      );
      pendingRefreshResponse!
          .then(() => {
            pendingRefreshResponse = undefined;
          })
          .catch(e => {
            // Smartpush API returns 400 if the refresh token is invalid
            if (typeof e.response !== "undefined" && e.response.status === 400) {
              store.dispatch(userFailedLoggedWithRefreshToken());
            }
          });
    } else {
    }
    return pendingRefreshResponse;
  }
}
