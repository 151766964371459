const settings = {
  partnerName: 'EDF',
  partnerNameOfferPage: 'EDF',
  favicon: 'edf/images/favicon.png',
  logoFile: "phoenix/images/logo-phoenix-horizontal.png",
  links: {
    helpCenter : 'https://bonsplans-edf.zendesk.com/hc/fr',
  },
  styles: {
    primary: '#1057c8',
    primaryDarker: '#00599e',
    secondary: '#646869',
    secondaryDarker: '#2A3030'
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
    realm: "edf",
    clientId: "app",
    clientSecret: undefined,
    tokenFromPostMessage: true,
    subjectIssuer: process.env.REACT_APP_IAM_TOKEN_EXCHANGE_SUBJECT_ISSUER_EDF || process.env.NX_IAM_TOKEN_EXCHANGE_SUBJECT_ISSUER_EDF,
    sso: false
  },
  iFrameConfig: {
    targetOrigin: process.env.REACT_APP_POST_MESSAGE_ORIGIN_EDF,
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: false,
    onboarding: true,
    smartAccount: true,
    registration: false,
    hideCookiesBar: true,
    analytics: {
      gtm: false,
      matomo: true,
      iFrame: true
    }
  },
  specific: {
    offerReduction: true
  },
  widgetUrls: {
    dev: 'https://edf.widget.dev.smartpush.fr:4200',
    preprod: 'https://edf.widget.preprod.smartpush.fr',
    recette1: 'https://edf.widget.recette1.smartpush.fr',
    recette2: 'https://edf.widget.recette2.smartpush.fr',
    recette3: 'https://edf.widget.recette3.smartpush.fr',
    prod: 'https://edf.widget.phoenix.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 7,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_baVNOyJa.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 15,
      srcUrl: 'https://analytics.smartpush.fr/js/container_43wlyMOw.js'
    }
  }
}

export default settings;
