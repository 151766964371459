const settings = {
  partnerName: 'BPVF',
  logoFile: 'phoenix/images/logo-phoenix-horizontal.png',
  favicon: 'phoenix/images/favicon.png',
  links: {
    helpCenter : 'https://static.smartpush.fr/phoenix/help.html',
    boosterVideo: 'https://media.smartpush.fr/data/partners/bpvf/videos/BP_BOOSTERS_BPVF.mp4'
  },
  styles: {
    primaryLink: "#f1054a",
    bannerBackgroundPrimary: "#f1054a",
    bannerBackgroundSecondary: "#e11c42",
    bannerBackgroundThird: "#FFC200",
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_BONSPLANS || process.env.NX_BASE_URL_IAM_DOMAIN_BONSPLANS,
    realm: "bpvf",
    clientId: "app",
    clientSecret:undefined,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: false,
    onboarding: true,
    smartAccount: false,
    registration: true,
    analytics: {
      gtm: true ,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/bpvf/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/bpvf/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/bpvf/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/bpvf/images/offer-types/operation-mode-shop-coupon.png",
    }
  },
  widgetUrls: {
    dev: 'https://bpvf.widget.dev.smartpush.fr:4200',
    preprod: 'https://bpvf-widget-preprod.smartpush.fr',
    recette1: 'https://bpvf.widget.recette1.smartpush.fr',
    recette2: 'https://bpvf.widget.recette2.smartpush.fr',
    recette3: 'https://bpvf.widget.recette3.smartpush.fr',
    premtest: 'https://bpvf-widget-premtest.smartpush.fr',
    prod: 'https://bpvf-widget-prod.smartpush.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 10,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_rC7L4xUd.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 20,
      srcUrl: 'https://analytics.smartpush.fr/js/container_i09HiKYp.js'
    }
  }
}

export default settings;
