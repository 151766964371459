/*
 * Login Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  SUBMIT_LOGIN,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_LOGIN_ERROR,
  USER_LOG_OUT,
  CLEAR_DATA,
  SNACKBAR_SYSTEM_OPEN,
  SNACKBAR_SYSTEM_CLOSE,
  IS_NAVIGATION_CGU_OPEN,
  IS_LEGAL_MENTIONS_OPEN,
  SUBMIT_REFRESH_TOKEN,
  SUBMIT_REFRESH_TOKEN_SUCCESS,
  SUBMIT_REFRESH_TOKEN_ERROR,
  GET_USER_DATA_WITH_TOKEN,
  GET_USER_DATA_WITH_TOKEN_SUCCESS,
  GET_USER_DATA_WITH_TOKEN_ERROR,
  GET_CASHBACK,
  GET_CASHBACK_ERROR,
  GET_CASHBACK_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_ERROR,
  GET_ORDERS_SUCCESS,
  PUT_USER_DATA_PROFILE,
  PUT_USER_DATA_PROFILE_ERROR,
  PUT_USER_DATA_PROFILE_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  SUBMIT_REFRESH_TOKEN_KEYCLOAK,
  SUBMIT_REFRESH_TOKEN_KEYCLOAK_SUCCESS,
  SUBMIT_REFRESH_TOKEN_KEYCLOAK_ERROR,
  GET_LOCATION_OFFER_BEFORE_LOGIN,
  GET_LOCATION_OFFER_BEFORE_PAYMENT_ORDER,
  SET_OFFER_DISTANCE,
  SUBMIT_LOGIN_SUCCESS_KEYCLOAK,
  SUBMIT_CONSENT,
  INIT_APP_STATUS, INIT_APP_TOKEN_RECEIVED, TOGGLE_SIDEBAR_LEFT, TOGGLE_SIDEBAR_RIGHT,
} from '../constants/globalConstants';

/**
* Login the user, this action starts the request saga
*
* @return {object} An action object with a type of SUBMIT_LOGIN
*/
export function submitLogin(username: string, password: string) {
return {
  type: SUBMIT_LOGIN,
  username,
  password
};
}

/**
* Dispatched when the is correctly logged in by the request saga
*
* @param  {string}   accessToken   The access token used for authenticated router
* @param  {int}      refreshToken  The refresh token used for retrieve another token
*
* @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
*/
export function userLogged(accessToken: string, refreshToken: string) {
return {
  type: SUBMIT_LOGIN_SUCCESS,
  accessToken,
  refreshToken
};
}

/**
* Dispatched when loading user token fail
*
* @param  {object} error The error
*
* @return {object}       An action object with a type of SUBMIT_LOGIN_ERROR passing the error
*/
export function userLoginError(error: any) {
return {
  type: SUBMIT_LOGIN_ERROR,
  error,
};
}


/**
* Load the user data, this action starts the request saga
*
* @return {object}               An action object with a type of GET_USER_DATA_WITH_TOKEN
*/
export function getUserDataWithToken(onLoginPage?: boolean) {
  return {
      type: GET_USER_DATA_WITH_TOKEN,
      onLoginPage
  };
}

/**
* Dispatched when the data of the user are correctly retrieved by the request saga
*
* @param  {object}   userData        The user data retrieved by the request
*
* @return {object}                   An action object with a type of GET_USER_DATA_WITH_TOKEN_SUCCESS passing the offers
*/
export function getUserDataWithTokenSuccess(userData: any) {
  return {
      type: GET_USER_DATA_WITH_TOKEN_SUCCESS,
      userData
  };
}

/**
* Dispatched when user data loading fail
*
* @param  {object} error The error
*
* @return {object}       An action object with a type of GET_USER_DATA_WITH_TOKEN_ERROR passing the error
*/
export function getUserDataWithTokenError(error: any) {
  return {
      type: GET_USER_DATA_WITH_TOKEN_ERROR,
      error,
  };
}


/**
* Dispatched when the user log out
*
* @return {object} An action object with a type of GET_USER_DATA_SUCCESS passing the offers
*/
export function userLogout() {
return {
  type: USER_LOG_OUT
};
}

/**
* Dispatched when the user is logged out to clear all data
*
* @return {object} An action object with a type of CLEAR_DATA passing the offers
*/
export function clearData() {
return {
  type: CLEAR_DATA
};
}

/**
* Dispatched when a snackbar will be displayed
*
* @param  {object} typeOfAlert        Type of alert
* @param  {object} content            Content of snackbar
*
* @return {object}                    An action object with a type of SNACKBAR_SYSTEM_OPEN passing the content and type
*/
export function displaySnackBar(typeOfAlert: string, content: any) {
return {
  type: SNACKBAR_SYSTEM_OPEN,
  typeOfAlert,
  content
};
}

/**
* Dispatched when a snackbar will be hidden
*
* @return {object}             An action object with a type of CLEAR_DATA passing the offers
*/
export function hideSnackBar() {
return {
  type: SNACKBAR_SYSTEM_CLOSE,
};
}

/**
* Dispatched when TOSAccepted are not validated by user
*
* @return {object}  An action object with a type of IS_NAVIGATION_CGU_OPEN passing the content
*/
export function openOrCloseCGUModal() {
  return {
      type: IS_NAVIGATION_CGU_OPEN,
  };
}

/**
 * Dispatched when a snackbar will be hidden
 *
 * @param  {string}   src       The source needed to display data in iframe
 *
 * @return {object}             An action object with a type of CLEAR_DATA passing the offers
 */
export function openOrCloseLegalMentionsModal(src: string) {
  return {
    type: IS_LEGAL_MENTIONS_OPEN,
    src
  };
}


/**
* Dispatched when the is correctly re-logged with refresh tokenin by the request saga
*
* @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
*/
export function userTryToLoginWithRefreshToken() {
  return {
      type: SUBMIT_REFRESH_TOKEN,
  };
}

/**
* Dispatched when the is correctly re-logged with refresh tokenin by the request saga
*
* @param  {string}   accessToken   The access token used for authenticated router
* @param  {int}      refreshToken  The refresh token used for retrieve another token
*
* @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
*/
export function userLoggedWithRefreshToken(accessToken: string, refreshToken: string) {
  return {
      type: SUBMIT_REFRESH_TOKEN_SUCCESS,
      accessToken,
      refreshToken
  };
}


/**
* Dispatched when the failed re-logged with refresh tokenin by the request saga
*
* @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
*/
export function userFailedLoggedWithRefreshToken() {
  return {
      type: SUBMIT_REFRESH_TOKEN_ERROR
  };
}


/**
 * Dispatched when the is correctly re-logged with refresh token keycloak in by the request saga
 *
 * @return {object}      An action object with a type of SUBMIT_REFRESH_TOKEN_KEYCLOAK passing the repos
 */
export function getAccessWithRefreshToken() {
  return {
    type: SUBMIT_REFRESH_TOKEN_KEYCLOAK,
  };
}

/**
 * Dispatched when the is correctly re-logged with refresh token keycloak in by the request saga
 *
 * @param  {string}   accessToken   The access token used for authenticated router
 * @param  {int}      refreshToken  The refresh token used for retrieve another token
 *
 * @return {object}      An action object with a type of SUBMIT_REFRESH_TOKEN_KEYCLOAK_SUCCESS passing the repos
 */
export function getAccessWithRefreshTokenSuccess(accessToken: string, refreshToken: string) {
  return {
    type: SUBMIT_REFRESH_TOKEN_KEYCLOAK_SUCCESS,
    accessToken,
    refreshToken
  };
}


/**
 * Dispatched when the failed re-logged with refresh token keycloak in by the request saga
 *
 * @return {object}      An action object with a type of SUBMIT_REFRESH_TOKEN_KEYCLOAK_ERROR passing the repos
 */
export function getAccessWithRefreshTokenError() {
  return {
    type: SUBMIT_REFRESH_TOKEN_KEYCLOAK_ERROR,
  };
}

export function submitLoginSuccessKeycloak(accessToken: string, refreshToken: string) {
  return {
    type: SUBMIT_LOGIN_SUCCESS_KEYCLOAK,
    accessToken,
    refreshToken
  }
}

export function submitConsent() {
  return {
    type: SUBMIT_CONSENT,
  }
}



/*
 * Cashback User Data Actions
 */


/**
 * Modify user data, this action starts the request saga
 *
 * @return {object}           An action object with a type of GET_CASHBACK
 */
export function getUserCashback() {
  return {
    type: GET_CASHBACK
  };
}

/**
 * Dispatched when the user data is correctly send by the request saga
 *
 * @param  {object} cashBackData  User cashback data
 *
 * @return {object}               An action object with a type of GET_CASHBACK_SUCCESS passing the offers
 */
export function getUserCashbackSuccess(cashBackData: any) {
  return {
    type: GET_CASHBACK_SUCCESS,
    cashBackData
  };
}

/**
 * Dispatched when user data sending fail
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of GET_CASHBACK_ERROR passing the error
 */
export function getUserCashbackError(error: any) {
  return {
    type: GET_CASHBACK_ERROR,
    error,
  };
}

/**
 * Get user orders data, this action starts the request saga
 *
 * @return {object}           An action object with a type of GET_ORDERS
 */
export function getUserOrders() {
  return {
    type: GET_ORDERS
  };
}

/**
 * Dispatched when orders data from that user is correctly retrieve by the request saga
 *
 * @param  {object} ordersData  User orders data
 *
 * @return {object}               An action object with a type of GET_ORDERS_SUCCESS passing the orders
 */
export function getUserOrdersSuccess(ordersData: any) {
  return {
    type: GET_ORDERS_SUCCESS,
    ordersData
  };
}

/**
 * Dispatched when user data retrieving fail
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of GET_ORDERS_ERROR passing the error
 */
export function getUserOrdersError(error: any) {
  return {
    type: GET_ORDERS_ERROR,
    error,
  };
}

/**
 * Get user order data, this action starts the request saga
 *
 * @param  {object} orderId   Order ID to retrieve order data
 *
 * @return {object}           An action object with a type of GET_ORDER
 */
export function getUserOrder(orderId: string) {
  return {
    type: GET_ORDER,
    orderId
  };
}

/**
 * Dispatched when order data from that user is correctly retrieve by the request saga
 *
 * @param  {object} orderData  User order data
 *
 * @return {object}               An action object with a type of GET_ORDER_SUCCESS passing the order
 */
export function getUserOrderSuccess(orderData: any) {
  return {
    type: GET_ORDER_SUCCESS,
    orderData
  };
}

/**
 * Dispatched when user data retrieving fail
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of GET_ORDER_ERROR passing the error
 */
export function getUserOrderError(error: any) {
  return {
    type: GET_ORDER_ERROR,
    error,
  };
}


/**
 * Modify user data, this action starts the request saga
 *
 * @param  {object} userData  User data profile
 *
 * @return {object}           An action object with a type of PUT_USER_DATA_PROFILE
 */
export function putUserDataProfile(userData: any) {
  return {
    type: PUT_USER_DATA_PROFILE,
    userData
  };
}

/**
 * Dispatched when the user data is correctly send by the request saga
 *
 * @return {object} An action object with a type of PUT_USER_DATA_PROFILE_SUCCESS passing the offers
 */
export function putUserDataProfileSuccess(user) {
  return {
    type: PUT_USER_DATA_PROFILE_SUCCESS,
    user
  };
}

/**
 * Dispatched when user data sending fail
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of PUT_USER_DATA_PROFILE_ERROR passing the error
 */
export function putUserDataProfileError(error: any) {
  return {
    type: PUT_USER_DATA_PROFILE_ERROR,
    error,
  };
}

/**
 * Dispatch when user have navigated before login when have to return to his offer
 *
 * @param {string}  location The location pathname
 *
 * @return {string} An action object with a type of GET_LOCATION_OFFER_BEFORE_LOGIN
 */
export function getLocationOfferBeforeLogin(location: string) {
  return {
    type: GET_LOCATION_OFFER_BEFORE_LOGIN,
    location
  }
}


/**
 * Dispatch when user choose an order to pay
 *
 * @param {string}  location The location pathname
 *
 * @return {string} An action object with a type of GET_LOCATION_OFFER_BEFORE_PAYMENT_ORDER
 */
export function getLocationOfferBeforePaymentOrder(location: string) {
  return {
    type: GET_LOCATION_OFFER_BEFORE_PAYMENT_ORDER,
    location
  }
}

export function setDistance(zoom: number) {
  return {
    type: SET_OFFER_DISTANCE,
    zoom: zoom
  }
}

export function initAppStatus(isReady: boolean) {
  return {
    type: INIT_APP_STATUS,
    isReady
  }
}

export function initTokenReceived(isTokenReceived: boolean) {
  return {
    type: INIT_APP_TOKEN_RECEIVED,
    isTokenReceived
  }
}

export function toggleSidebar(leftOrRight: string) {
  return {
    type: leftOrRight === 'left' ? TOGGLE_SIDEBAR_LEFT : TOGGLE_SIDEBAR_RIGHT,
  }
}
