import { createSelector } from 'reselect';
import { globalInitialState } from "../reducers/globalReducer";

export const selectGlobal = (state: any) => state.global || globalInitialState;

/** User token selector */
export const makeSelectLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.loading,
    );
export const makeSelectUserCguLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.userCguLoading,
  );
export const makeSelectUserRegisterLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userRegisterLoading,
    );
export const getToken = () =>
  createSelector(
      selectGlobal,
    globalState => globalState.auth.userData.accessToken,
  );
export const makeSelectRefreshToken = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth.userData.refreshToken
  )
export const getIframeToken = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.iframeToken,
  );
export const getCredentialToken = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.credentialToken,
  );

export const getResponseKeycloak = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth.responseTokenKeyCloak,
  );
export const getTokenAccessKeyCloak = () =>
  createSelector(
    selectGlobal,
      globalState => globalState.auth.accessTokenKeyCloak,
  )
export const getRefreshTokenKeycloak = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth.refreshTokenKeyCloak,
  )

export const makeSelectLoginError = () =>
    createSelector(
        selectGlobal,
      globalState => globalState.auth.error
    );

/** User profile selectors*/
export const makeSelectUserData = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.data,
    );
export const makeSelectUserDataCoordinates = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.data.coordinates,
    );
export const makeSelectUserDataError = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.error
    );
export const makeSelectUserDataLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.loading
    );

export const makeSelectUserDataProfileloading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.loadingProfile
    );

export const makeSelectUserDataOrderloading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.loadingOrders
    );
export const makeSelectSnackBar = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.snackbar
    );
export const makeSelectCGUOpen = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.navigation.isCGUOpen
    );
export const makeSelectLegalMentions = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.navigation.legalMentions
    );

export const makeSelectUserLocationBeforeLogin = () =>
  createSelector(
    selectGlobal,
      globalState => globalState.navigation.locationBeforeLogin
  )

export const makeSelectUserLocationBeforePaymentOrder = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.navigation.locationBeforePaymentOrder
  )

export const makeSelectMapInformations = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.map
  )

/**
 * Cashback
 */
export const selectUserCashback = (state: any) => state.global.auth.userData.cashback || {};

export const makeSelectUserCashBack = () =>
    createSelector(
        selectUserCashback,
        cashbackState => cashbackState.data
    );

export const makeSelectCashbackAmountPayable = () =>
    createSelector(
        selectUserCashback,
        cashbackState => cashbackState.amountPayable
    );

export const makeSelectCashbackAmountPending = () =>
    createSelector(
        selectUserCashback,
        cashbackState => cashbackState.amountPending
    );

export const makeSelectCashbackAmountPaid = () =>
    createSelector(
        selectUserCashback,
        cashbackState => cashbackState.amountPaid
    );

/** Orders */
export const selectUserAccountOrders = (state: any) => state.global.auth.userData.orders || [];

export const makeSelectUserOrders = () =>
    createSelector(
      selectUserAccountOrders,
      userAccountState => userAccountState
    );

/** Order */

export const selectUserAccountOrder = (state: any) => state.global.auth.userData.order || {};

export const makeSelectUserOrder = () =>
    createSelector(
      selectUserAccountOrder,
      userAccountState => userAccountState
    );


/** Partner */

export const selectPartnerSettings = (state: any) => state.global.auth.partnerSettings || {};

export const makeSelectPartnerPrimaryColor = () =>
    createSelector(
      selectPartnerSettings,
      partnerState => partnerState.color1
    );

export const makeSelectPartnerLoading = () =>
    createSelector(
      selectPartnerSettings,
      partnerState => partnerState.loading
    );

export const makeSelectPartnerError = () =>
    createSelector(
      selectPartnerSettings,
      partnerState => partnerState.error
    );

export const makeSelectIsAppReady = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth.appIsReady
  );

export const makeSelectIsTokenReceived = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth.tokenReceived
  );


export const makeSelectSidebarVisibilityLeft = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.sidebar.leftIsVisible
  )

export const makeSelectSidebarVisibilityRight = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.sidebar.rightIsVisible
  )
