const settings = {
  partnerName: 'ReducAvenue',
  partnerNameOfferPage: 'Reducavenue',
  logoFile: 'reducavenue/images/phoenixnoir-by-reducavenue-noir.png',
  favicon: 'phoenix/images/favicon.png',
  vipCode: 'REDUCAVENUE',
  titleNavigation: 'phoenix by Reducavenue',
  links: {
    helpCenter : 'https://phoenix-by-reducavenue.zendesk.com/hc/fr',
    legalMentions : 'https://help.smartpush.fr/reducavenue/mentions-legales/',
    termsOfUse: 'https://help.smartpush.fr/reducavenue/cgs-smartrewards/',
    cookiesPolicy :'https://help.smartpush.fr/reducavenue/politique-de-cookies/',
    privacyPolicy : 'https://help.smartpush.fr/reducavenue/confidentialite/',
    discountCoupons : 'https://help.smartpush.fr/reducavenue/conditions-particulieres-offres-bons-dachat/',
    contact : 'mailto:support.phoenix-by-reducavenue@smartpush.fr',
    support : 'mailto:support.phoenix-by-reducavenue@smartpush.fr',
    textCarousel: true,
    carousel1: "https://media.smartpush.fr/data/images/logos.webp",
    carousel2: "https://media.smartpush.fr/data/images/thinking.jpg",
    carousel3: "https://media.smartpush.fr/data/images/lock.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/images/whoareyou.jpg",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/images/preferredBrands.jpeg",
    onBoardingHabits: "https://media.smartpush.fr/data/images/habits.jpeg",
    onBoardingProjects: "https://media.smartpush.fr/data/images/forgot-password.webp"
  },
  styles: {
    primaryLink: "#f1054a",
    bannerBackgroundPrimary: "#f1054a",
    bannerBackgroundSecondary: "#e11c42",
    bannerBackgroundThird: "#FFC200",
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_PHOENIX || process.env.NX_BASE_URL_IAM_DOMAIN_PHOENIX,
    realm: "reducavenue",
    clientId: "app",
    clientSecret: process.env.REACT_APP_IAM_API_REDUCAVENUE_CLIENT_SECRET,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: true,
    smartAccount: false,
    onboarding: true,
    registration: true,
    analytics: {
      gtm: true ,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/reducavenue/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/reducavenue/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/reducavenue/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/reducavenue/images/offer-types/operation-mode-shop-coupon.png",
    }
  },
  widgetUrls: {
    dev: 'https://reducavenue.widget.dev.phoenix.fr:4200',
    preprod: 'https://reducavenue-widget-preprod.smartpush.fr',
    recette1: 'https://reducavenue.widget.recette1.smartpush.fr',
    recette2: 'https://reducavenue.widget.recette2.smartpush.fr',
    recette3: 'https://reducavenue.widget.recette3.smartpush.fr',
    prod: 'https://reducavenue.widget.phoenix.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 9,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_cDRGwZN4.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 14,
      srcUrl: 'https://analytics.smartpush.fr/js/container_tvVV80AK.js'
    }
  }
}

export default settings;
