import {createMuiTheme, fade} from "@material-ui/core";
import {COLORS} from "../variables";

export const theme = createMuiTheme({
  spacing: 4,
  typography: {
    fontFamily: "ProximaNova, Arial"
  },
  palette: {
    background: {
      default: fade(COLORS.white, 0.06)
    },
    primary: {
      main: COLORS.primary,
      dark: COLORS.primaryDarker,
    },
    secondary: {
      main: COLORS.secondary,
      dark: COLORS.secondaryDarker,
    },
    error: {
      main: COLORS.error
    }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: 'red'
      }
    },
    MuiContainer: {
      root: {
        minHeight: '70%',
      }
    },
    MuiLink: {
      root: {
        cursor: "pointer"
      }
    },
    MuiCssBaseline: {
      '@global': {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",
        // '*::-webkit-scrollbar': {
        //   width: '0.8em'
        // },
        // '*::-webkit-scrollbar-track': {
        //   boxShadow: `inset 0 0 6px ${fade(COLORS.primary, 0.4)}`,
        //   webkitBoxShadow: `inset 0 0 6px ${fade(COLORS.primary, 0.4)}`
        // },
        // '*::-webkit-scrollbar-thumb': {
        //   backgroundColor: COLORS.primary,
        //   outline: '1px solid slategrey'
        // },
        // '.MuiPopover-root ::-webkit-scrollbar, .MuiAutocomplete-paper::-webkit-scrollbar': {
        //   width: 5,
        // },
        // '.MuiPopover-root ::-webkit-scrollbar-track, .MuiAutocomplete-paper::-webkit-scrollbar-track': {
        //   boxShadow: "none",
        //   background: "#ebedef",
        // },
        // '.MuiPopover-root ::-webkit-scrollbar-thumb, .MuiAutocomplete-paper::-webkit-scrollbar-thumb': {
        //   backgroundColor: COLORS.greyLighter3,
        // },
      },

    },

    //MuiCssBaseline: {
    //	"@global": {
    //	  body: {
    //		  backgroundImage:
    //			  "url(https://designshack.net/wp-content/uploads/gradient-background.jpg)"
    //	  }
    //	}
    //},
    MuiBreadcrumbs: {
      ol: {
        marginBottom: '20px;'
      }
    },
    MuiButton: {
      root: {
        borderRadius: "17px",
        lineHeight: "18px",
        textTransform: "none",
        fontSize: "16px",
        fontFamily: "ProximaNovaBold, Arial",
        padding: "8px 16px",
      },
      outlined: {
        padding: "8px 16px",
      },
      contained: {
        boxShadow: "none"
      },
      containedSecondary: {
        color: COLORS.white
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "none"
      }
    },
    MuiTableCell: {
      root: {
        padding: '8px'
      },
      head: {
        color: COLORS.black,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '27px',
        backgroundColor: "transparent"
      },
      stickyHeader: {
        backgroundColor: COLORS.primary
      }
    },
    MuiFab: {
      extended: {
        height : '35px',
        '&$sizeSmall': {
          height: "34px",
          padding: '0 16px'
        },
      },
      label : {
        textTransform: 'none',
        fontWeight: 'bold'
      }
    },
    MuiTabs: {
      indicator: {
        height: '4px'
      }
    },
    MuiTab: {
      wrapper: {
        color: COLORS.primary
      },
      root: {
        minWidth: 'inherit !important',
        borderBottom: '1px solid #98C93E'
      },
      textColorInherit: {
        opacity: '1',
        fontWeight: '300',
        textTransform: 'none',
        fontSize: '15px',
        '&$selected': {
          fontWeight: 'bold'
        }
      }
    },
    MuiTypography: {
      root: {
        fontFamily: "ProximaNova, Arial",
        fontSize: '16px',
      },
      h2: {
        fontSize: '16px',
        fontFamily: "ProximaNovaBold, Arial",
        fontWeight: 'bold',
        color: COLORS.primary
      },
      h5: {
        color: COLORS.primary,
        fontFamily: "ProximaNovaBold, Arial",
        marginBottom: '10px',
        lineHeight: "29px",
      },
      h6: {
        color: COLORS.primary,
        fontFamily: "ProximaNovaBold, Arial",
        marginBottom: '10px',
        marginTop: '10px',
        fontSize: "16px",
        lineHeight: "19px",
      },
      body1:{
        fontSize: "16px",
        lineHeight: "19px",
      },
      body2: {
        fontWeight: 800
      },
      subtitle1 : {
        fontSize: '0.75rem',
        //12px
      },
      subtitle2 : {
        fontSize: '0.875rem',
        fontWeight: 800
        //14px
      }
    },
    MuiCardActions: {
      root: {
        padding: 0
      }
    },
    MuiDrawer: {
      paper: {
        overflowX: "hidden",
      }
    },
    MuiPopover: {
      root: {
        padding: "20px",
      },
      paper: {
        marginTop: "10px",
        padding: "8px 20px",
        boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
        overflow: "visible !important",
        "&::before": {
          content: "''",
          position: "absolute",
          top: "-12px",
          left: "25px",
          borderBottom: "6px solid white",
          borderRight: "6px solid transparent",
          borderLeft: "6px solid transparent",
          borderTop: "6px solid transparent",
          zIndex: "10"
        },
        "& .MuiRadio-root": {
          padding: "4px",
          color: COLORS.primary,
          "&.Mui-checked": {
            color: COLORS.primary,
          }
        },
        "& .MuiFormControlLabel-label": {
          fontFamily: "ProximaNovaBold, Arial",
          color: COLORS.primary,
        }
      }
    },
    MuiSlider: {
      thumb: {
        boxShadow: "none",
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(12px, 11px) scale(1)",
        "&.MuiInputLabel-shrink": {
          transform: "translate(12px, 20px) scale(0)",
        }
      },
      '& .MuiSelect-root': {
        backgroundColor: '#e2e9ec',
        borderRadius: 5,
        color: '#232323'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
        borderRadius: 5
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(10px, 10px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0);'
      }
    },
    MuiInputBase: {
      input: {
        background: COLORS.input,
        borderRadius: "5px",
        height: "36px",
        border: "none",
        marginBottom: "16px",
        boxSizing: "border-box",
      },
      select: {

        background: COLORS.input,
        borderRadius: 5,
        height: 36,
        border: "none",
        marginBottom: 16,
        boxSizing: "border-box",
      }
    },
    MuiSelect: {
      selectMenu: {
        height: 36,
        lineHeight: "16px",
      },
      icon: {
        top: "calc(50% - 19px)",
      }
    },
    MuiAutocomplete: {
      input: {
        background: "transparent !important",
        margin: "0 !important",
        height: "30px !important",
      }
    },
    MuiAlertTitle: {
      root: {
        fontFamily: "ProximaNovaBold, Arial",
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        color: COLORS.primary,
        '&$checked': {
          color: COLORS.primary,
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: COLORS.primary,
        '&$checked': {
          color: COLORS.primary,
        },
      },
    },
  },
});
