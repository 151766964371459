const settings = {
  partnerName: 'France Abonnements',
  logoFile: 'adlp/images/logo-adlp.png',
  favicon: 'adlp/images/favicon.png',
  titleNavigation: 'France Abonnements',
  links: {
    helpCenter : 'https://faplus.zendesk.com/hc/fr',
    legalMentions : 'https://help.smartpush.fr/france-abonnements/mentions/',
    termsOfUse: 'https://help.smartpush.fr/france-abonnements/cgs/',
    cookiesPolicy :'https://help.smartpush.fr/france-abonnements/politique-de-cookies-site-web-fa/',
    privacyPolicy : 'https://help.smartpush.fr/france-abonnements/privacy/',
    contact : 'mailto:contact@faplus.fr',
    support : 'mailto:contact@faplus.fr',
    textCarousel: true,
    carousel1: "https://media.smartpush.fr/data/images/logos.webp",
    carousel2: "https://media.smartpush.fr/data/images/thinking.jpg",
    carousel3: "https://media.smartpush.fr/data/images/lock.webp",
    carousel4: "https://media.smartpush.fr/data/images/videoSlider.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/images/whoareyou.jpg",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/images/preferredBrands.jpeg",
    onBoardingHabits: "https://media.smartpush.fr/data/images/habits.jpeg",
    onBoardingProjects: "https://media.smartpush.fr/data/images/forgot-password.webp",
    offerCoupons: "https://help.smartpush.fr/france-abonnements/conditions-particulieres-offres-coupons/",
    cashbackCoupons: "https://help.smartpush.fr/france-abonnements/conditions-particulieres-offres-cashback/",
    discountCoupons: "https://help.smartpush.fr/france-abonnements/conditions-particulieres-bons-dachat/",
    boosterVideo: "https://media.smartpush.fr/data/partners/adlp/videos/FAPLUS-BOOSTERS.mp4"
  },
  styles: {
    primaryLink: '#00599e',
    primary: '#0060a9',
    primaryDarker: '#00599e',
    bannerBackgroundPrimary: '#E51E28',
    bannerBackgroundSecondary: '#E51E28',
    bannerBackgroundThird: '#E51E28'
  },

  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
    realm: "faplus",
    clientId: "app",
    clientSecret: process.env.REACT_APP_IAM_API_FAPLUS_CLIENT_SECRET,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: false,
    onboarding: true,
    smartAccount: false,
    registration: true,
    analytics: {
      gtm: true ,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/adlp/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/adlp/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/adlp/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/adlp/images/offer-types/operation-mode-shop-coupon.png",
    }
  },
  widgetUrls: {
    dev: 'https://faplus.widget.dev.smartpush.fr:4200',
    preprod: 'https://faplus-widget-preprod.smartpush.fr',
    recette1: 'https://faplus.widget.recette1.smartpush.fr',
    recette2: 'https://faplus.widget.recette2.smartpush.fr',
    recette3: 'https://faplus.widget.recette3.smartpush.fr',
    prod: 'https://faplus.widget.smartpush.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 15,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_brp7iBWm.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 16,
      srcUrl: 'https://analytics.smartpush.fr/js/container_YTZXku2z.js'
    }
  }
}

export default settings;
