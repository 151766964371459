/*
 * Login Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  SUBMIT_LOGIN,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_LOGIN_ERROR,
  USER_LOG_OUT,
  CLEAR_DATA,
  SNACKBAR_SYSTEM_OPEN,
  SNACKBAR_SYSTEM_CLOSE,
  SUBMIT_REFRESH_TOKEN,
  SUBMIT_REFRESH_TOKEN_SUCCESS,
  SUBMIT_REFRESH_TOKEN_ERROR,
  GET_USER_DATA_WITH_TOKEN,
  GET_USER_DATA_WITH_TOKEN_SUCCESS,
  GET_USER_DATA_WITH_TOKEN_ERROR,
  DELETE_USER_DATA,
  DELETE_USER_DATA_SUCCESS,
  DELETE_USER_DATA_ERROR,
  USER_SSO,
  USER_CONFIRM_REGISTRATION,
  USER_CONFIRM_REGISTRATION_SUCCESS,
  USER_CONFIRM_REGISTRATION_ERROR,
} from './constants';
import {IS_NAVIGATION_CGU_OPEN} from '@smartpush-front/store';

/**
 * Login the user, this action starts the request saga
 *
 * @return {object} An action object with a type of SUBMIT_LOGIN
 */
export function submitLogin(username: string, password: string) {
  return {
    type: SUBMIT_LOGIN,
    username,
    password
  };
}

/**
 * Dispatched when the is correctly logged in by the request saga
 *
 * @param  {string}   accessToken   The access token used for authenticated router
 * @param  {int}      refreshToken  The refresh token used for retrieve another token
 *
 * @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
 */
export function userLogged(accessToken: string, refreshToken: string) {
  return {
    type: SUBMIT_LOGIN_SUCCESS,
    accessToken,
    refreshToken
  };
}

/**
 * Dispatched when loading user token fail
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of SUBMIT_LOGIN_ERROR passing the error
 */
export function userLoginError(error: any) {
  return {
    type: SUBMIT_LOGIN_ERROR,
    error,
  };
}


/**
 * Load the user data, this action starts the request saga
 *
 * @return {object}               An action object with a type of GET_USER_DATA_WITH_TOKEN
 */
export function getUserDataWithToken(onLoginPage?: boolean) {
  return {
    type: GET_USER_DATA_WITH_TOKEN,
    onLoginPage
  };
}

/**
 * Dispatched when the data of the user are correctly retrieved by the request saga
 *
 * @param  {object}   userData        The user data retrieved by the request
 *
 * @return {object}                   An action object with a type of GET_USER_DATA_WITH_TOKEN_SUCCESS passing the offers
 */
export function getUserDataWithTokenSuccess(userData: any) {
  return {
    type: GET_USER_DATA_WITH_TOKEN_SUCCESS,
    userData
  };
}

/**
 * Dispatched when user data loading fail
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of GET_USER_DATA_WITH_TOKEN_ERROR passing the error
 */
export function getUserDataWithTokenError(error: any) {
  return {
    type: GET_USER_DATA_WITH_TOKEN_ERROR,
    error,
  };
}


/**
 * Dispatched when the user log out
 *
 * @return {object} An action object with a type of GET_USER_DATA_SUCCESS passing the offers
 */
export function userLogout() {
  return {
    type: USER_LOG_OUT
  };
}

/**
 * Dispatched when the user is logged out to clear all data
 *
 * @return {object} An action object with a type of CLEAR_DATA passing the offers
 */
export function clearData() {
  return {
    type: CLEAR_DATA
  };
}

/**
 * Dispatched when a snackbar will be displayed
 *
 * @param  {object} typeOfAlert        Type of alert
 * @param  {object} content            Content of snackbar
 *
 * @return {object}                    An action object with a type of SNACKBAR_SYSTEM_OPEN passing the content and type
 */
export function displaySnackBar(typeOfAlert: string, content: any) {
  return {
    type: SNACKBAR_SYSTEM_OPEN,
    typeOfAlert,
    content
  };
}

/**
 * Dispatched when a snackbar will be hidden
 *
 * @return {object}             An action object with a type of CLEAR_DATA passing the offers
 */
export function hideSnackBar() {
  return {
    type: SNACKBAR_SYSTEM_CLOSE,
  };
}


/**
 * Dispatched when the is correctly re-logged with refresh tokenin by the request saga
 *
 * @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
 */
export function userTryToLoginWithRefreshToken() {
  return {
    type: SUBMIT_REFRESH_TOKEN,
  };
}

/**
 * Dispatched when the is correctly re-logged with refresh tokenin by the request saga
 *
 * @param  {string}   accessToken   The access token used for authenticated router
 * @param  {int}      refreshToken  The refresh token used for retrieve another token
 *
 * @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
 */
export function userLoggedWithRefreshToken(accessToken: string, refreshToken: string) {
  return {
    type: SUBMIT_REFRESH_TOKEN_SUCCESS,
    accessToken,
    refreshToken
  };
}


/**
 * Dispatched when the failed re-logged with refresh tokenin by the request saga
 *
 * @return {object}      An action object with a type of SUBMIT_LOGIN_SUCCESS passing the repos
 */
export function userFailedLoggedWithRefreshToken() {
  return {
    type: SUBMIT_REFRESH_TOKEN_ERROR
  };
}


/**
 * User delete account, this action starts the request saga
 *
 * @return {object} An action object with a type of DELETE_USER_DATA
 */
export function userDeleteData() {
  return {
    type: DELETE_USER_DATA
  }
}


/**
 * Dispatched when the user is correctly delete by the request saga
 *
 * @return {object}      An action object with a type of DELETE_USER_DATA_SUCCESS passing the repos
 */
export function userDeleteDataSuccess(data: any) {
  return {
    type: DELETE_USER_DATA_SUCCESS,
    data
  }
}


/**
 * Dispatched when the delete of user failed
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of DELETE_USER_DATA_ERROR passing the error
 */
export function userDeleteDataError(error: any) {
  return {
    type: DELETE_USER_DATA_ERROR,
    error
  }
}

export function userSSO(queryParams: any) {
  return {
    type: USER_SSO,
    queryParams
  }
}

export function userConfirmRegistration(partnerId: string, key: string) {
  return {
    type: USER_CONFIRM_REGISTRATION,
    partnerId,
    key
  }
}

export function userConfirmRegistrationSuccess() {
  return {
    type: USER_CONFIRM_REGISTRATION_SUCCESS
  }
}

export function userConfirmRegistrationError(error: Error) {
  return {
    type: USER_CONFIRM_REGISTRATION_ERROR,
    error
  }
}


/**
 * Dispatched when a TOSAccepted where note validated
 *
 * @return {object}             An action object with a type of CLEAR_DATA passing the offers
 */
export function openOrCloseCGUModal() {
  return {
    type: IS_NAVIGATION_CGU_OPEN,
  };
}
