import {SETTINGS} from "@smartpush-front/styles";

var url = process.env.REACT_APP_SMARTPUSH_API_BASE_URL || process.env.NX_SMARTPUSH_API_BASE_URL;

if (SETTINGS.partnerName === 'EDF') {
  url = url.replace('https://','')
  url = 'https://edf.' + url;
}

export const baseUrl = url || process.env.REACT_APP_SMARTPUSH_API_BASE_URL;
