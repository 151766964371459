const settings = {
  partnerName: 'Ace',
  logoFile: 'ace/images/logo-ace.png',
  favicon: 'ace/images/favicon.png',
  titleNavigation: 'Ace',
  links: {
    helpCenter : 'https://aceetvous.zendesk.com/hc/fr',
    legalMentions : 'https://help.smartpush.fr/aceetvous/mentions/',
    termsOfUse: 'https://help.smartpush.fr/aceetvous/cgs/',
    cookiesPolicy :'https://help.smartpush.fr/aceetvous/cookies/',
    privacyPolicy : 'https://help.smartpush.fr/aceetvous/privacy/',
    discountCoupons : 'https://help.smartpush.fr/aceetvous/conditions-particulieres-bons-dachat/',
    cashbackCoupons : 'https://help.smartpush.fr/aceetvous/conditions-particulieres-offres-cashback/',
    offerCoupons : 'https://help.smartpush.fr/aceetvous/conditions-particulieres-offres-coupons/',
    contact : 'mailto:support.aceetvous@smartpush.fr',
    support : 'mailto:support.aceetvous@smartpush.fr?subject=Support',
    textCarousel: true,
    carousel1: "https://media.smartpush.fr/data/images/logos.webp",
    carousel2: "https://media.smartpush.fr/data/images/thinking.jpg",
    carousel3: "https://media.smartpush.fr/data/images/lock.webp",
    onBoardingWhoAreYou: "https://media.smartpush.fr/data/partners/ace/homescreens/whoareyou.webp",
    onBoardingPreferredBrands:"https://media.smartpush.fr/data/partners/ace/homescreens/preferredBrands.webp",
    onBoardingHabits: "https://media.smartpush.fr/data/partners/ace/homescreens/habits.webp",
    onBoardingProjects: "https://media.smartpush.fr/data/partners/ace/homescreens/forgot-password.webp",
    boosterVideo: "https://media.smartpush.fr/data/partners/ace/videos/ACE-BOOSTERS.mp4"
  },
  styles: {
    primaryLink: '#D91828',
    primary: '#D91828',
    primaryDarker: '#D91828',
    bannerBackgroundPrimary: '#D91828',
    bannerBackgroundSecondary: '#A71618',
    bannerBackgroundThird: '#FFFFFF'
  },
  iam: {
    url: process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
    realm: "ace",
    clientId: "app",
    clientSecret: process.env.REACT_APP_IAM_API_ACE_CLIENT_SECRET,
    tokenFromPostMessage: false,
    subjectIssuer: "",
    sso: false
  },
  iFrameConfig: {
    targetOrigin: "*",
    performanceMessage: false,
  },
  features: {
    optionalOnboarding: true,
    smartAccount: false,
    registration: true,
    onboarding: true,
    analytics: {
      gtm: true ,
      matomo: true,
      iFrame: false
    }
  },
  specific: {
    operationModePicture: {
      ecard: "https://media.smartpush.fr/data/partners/ace/images/offer-types/operation-mode-ecard.png",
      cashback: "https://media.smartpush.fr/data/partners/ace/images/offer-types/operation-mode-cashback.png",
      webCoupon: "https://media.smartpush.fr/data/partners/ace/images/offer-types/operation-mode-web-coupon.png",
      shopCoupon: "https://media.smartpush.fr/data/partners/ace/images/offer-types/operation-mode-shop-coupon.png",
    }
  },
  widgetUrls: {
    dev: 'https://ace.widget.dev.smartpush.fr:4200',
    preprod: 'https://ace-widget-preprod.smartpush.fr',
    recette1: 'https://ace.widget.recette1.smartpush.fr',
    recette2: 'https://ace.widget.recette2.smartpush.fr',
    recette3: 'https://ace.widget.recette3.smartpush.fr',
    prod: 'https://ace.widget.smartpush.fr',
  },
  matomo: {
    preprod: {
      urlBase: 'https://analytics.preprod.smartpush.fr',
      siteId: 19,
      srcUrl: 'https://analytics.preprod.smartpush.fr/js/container_Bu0VbDzf.js'
    },
    prod: {
      urlBase: 'https://analytics.smartpush.fr',
      siteId: 19,
      srcUrl: 'https://analytics.smartpush.fr/js/container_ASivTyVb.js'
    }
  }
}

export default settings;
