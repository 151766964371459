import axios, { AxiosError } from "axios";

import {history} from '@smartpush-front/store';
import {isKeycloakAuthentication, keycloak, logoutKeycloak} from '../utils/keycloak';
import {SETTINGS} from "@smartpush-front/styles";

axios.interceptors.request.use(async (config) => {
    if (isKeycloakAuthentication() && keycloak.authenticated && keycloak.isTokenExpired(120)) {
      const isUpdated = await keycloak.updateToken(10);
      if (isUpdated) {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      }
   }

  return config;
});

axios.interceptors.response.use(response => {
  return response;
}, refreshTokenInterceptorError);

export async function refreshTokenInterceptorError(error: AxiosError) {
    if (error.response && 401 === error.response.status && isKeycloakAuthentication()) {
      await logoutKeycloak();
    }
    if (error.response && SETTINGS?.iFrameConfig.performanceMessage) {
      window.parent.postMessage(
        {
          error: error.response.data?.error,
          code: error.response.status
        },
        SETTINGS.iFrameConfig.targetOrigin
      );
    }

    if (typeof error.response === "undefined") {
      return history.push("/maintenance");
    }

    return Promise.reject(error);
}

